import React, { useState, useEffect } from 'react';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { app } from '../resources/gcp-config';
import { useNavigate } from 'react-router-dom';
import { successAlert, errorAlert } from '../components/alerts';
import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';

const auth = getAuth(app);
const db = getFirestore(app);

function Auth() {
  const [username, setUsername] = useState(localStorage.getItem('email') || '');
  const [password, setPassword] = useState('');

  let navigate = useNavigate();

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it is
used to listen for changes in the authentication state using the `onAuthStateChanged` function from
Firebase. */
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/dashboard'); // Redirect to dashboard
      }
    });
  }, [navigate]);

  /**
   * The function `handleForgotPassword` checks if a username is provided and sends a password reset
   * email using the `sendPasswordResetEmail` function, displaying success or error alerts accordingly.
   * @param event - The `event` parameter is an object that represents the event that triggered the
   * function. In this case, it is likely an event object related to a form submission or button click.
   * The function is using `event.preventDefault()` to prevent the default behavior of the event, which
   * is typically a page refresh or
   * @returns nothing (undefined).
   */
  const handleForgotPassword = (event) => {
    if (!username) {
      errorAlert('Error!', 'Please add your email address.');
      return;
    }

    event.preventDefault();
    sendPasswordResetEmail(auth, username)
      .then(() => {
        successAlert('Success!', 'Password reset email sent!');
      })
      .catch((error) => {
        errorAlert(
          'Error!',
          'Unable to send Password reset email, please try again later.'
        );
      });
  };

  /**
   * The handleSubmit function prevents the default form submission behavior, signs in with the provided
   * username and password using Firebase authentication, and displays an error message if there is an
   * error.
   * @param event - The event parameter represents the event that triggered the form submission. In this
   * case, it is likely a submit event from a form element.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, username, password).catch((error) => {
      errorAlert('Error!', 'Please try again later');
    });
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log("User data ==> ", user);
        await setDoc(doc(db, 'users', user.uid), {
          name: user.displayName,
          username: user.displayName,
          role: 'User',
          primaryPhone: user.phoneNumber,
          primaryEmail: user.email,
          photoURL: user.photoURL,
          timestamp: serverTimestamp(),
        });
      })
      .catch((error) => {
        console.log("Sign in error => ", error);
      });
  };

  return (
    <section className="w-full px-8 py-16 bg-gray-100 xl:px-8 h-screen flex items-center">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-col items-center md:flex-row">
          <div className="w-full space-y-5 md:w-3/5 md:pr-16">
            <p className="font-medium text-blue-500 uppercase">
              Building Businesses
            </p>
            <h2 className="text-2xl font-extrabold leading-none text-black sm:text-3xl md:text-5xl">
              Changing The Way People Do Business.
            </h2>
            <p className="text-xl text-gray-600 md:pr-16">
              Learn how to engage with your visitors and teach them about your
              mission. We're revolutionizing the way customers and businesses
              interact.
            </p>
          </div>
          <div className="w-full mt-16 md:mt-0 md:w-2/5">
            <div className="relative z-10 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7">
              <h3 className="mb-6 text-2xl font-medium text-center">
                {localStorage.getItem('name') ? (
                  <h3 className="mb-6 text-2xl font-medium text-center">
                    Welcome backkkkkkkkkkkkkkkkkkkk, {localStorage.getItem('name','name','name')}!
                  </h3>
                ) : (
                  <h3 className="mb-6 text-2xl font-medium text-center">
                    Sign in to your Account
                  </h3>
                )}
              </h3>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="block w-full px-4 py-3 mb-4 border-2 border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                  placeholder="Email address"
                  defaultValue={localStorage.getItem('email') || ''}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full px-4 py-3 mb-4 border-2 border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  onClick={handleSubmit}
                  type="button"
                  class="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                >
                  Continue
                </button>
              </form>

              <div class="flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-neutral-500 dark:before:border-neutral-700 dark:after:border-neutral-700 py-5">
                Or
              </div>

              <button
                type="button"
                class="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200 dark:bg-white/10 dark:hover:bg-white/20 dark:text-white dark:hover:text-white dark:focus:bg-white/20"
                onClick={signInWithGoogle}
              >
                <svg
                  class="size-4"
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4132_5805)">
                    <path
                      d="M32.2566 16.36C32.2566 15.04 32.1567 14.08 31.9171 13.08H16.9166V19.02H25.7251C25.5454 20.5 24.5866 22.72 22.4494 24.22L22.4294 24.42L27.1633 28.1L27.4828 28.14C30.5189 25.34 32.2566 21.22 32.2566 16.36Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M16.9166 32C21.231 32 24.8463 30.58 27.5028 28.12L22.4694 24.2C21.1111 25.14 19.3135 25.8 16.9366 25.8C12.7021 25.8 9.12677 23 7.84844 19.16L7.66867 19.18L2.71513 23L2.65521 23.18C5.2718 28.4 10.6648 32 16.9166 32Z"
                      fill="#34A853"
                    />
                    <path
                      d="M7.82845 19.16C7.48889 18.16 7.28915 17.1 7.28915 16C7.28915 14.9 7.48889 13.84 7.80848 12.84V12.62L2.81499 8.73999L2.6552 8.81999C1.55663 10.98 0.937439 13.42 0.937439 16C0.937439 18.58 1.55663 21.02 2.63522 23.18L7.82845 19.16Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M16.9166 6.18C19.9127 6.18 21.9501 7.48 23.0886 8.56L27.6027 4.16C24.8263 1.58 21.231 0 16.9166 0C10.6648 0 5.27181 3.6 2.63525 8.82L7.80851 12.84C9.10681 8.98 12.6821 6.18 16.9166 6.18Z"
                      fill="#EB4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4132_5805">
                      <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(0.937439)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Sign in with Google
              </button>
              <p className="w-full mt-4 text-sm text-center text-gray-500">
                Forgot Password{' '}
                <button
                  onClick={handleForgotPassword}
                  className="text-blue-500 underline"
                >
                  Click Here
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

/**
 * The `handleLogout` function signs out the user and navigates to the login page, and logs an error if
 * signing out fails.
 * @param navigate - The `navigate` parameter is a function that is used to navigate to a different
 * page or route in your application. It is typically provided by a routing library like React Router.
 * In this case, the `navigate` function is used to navigate to the '/login' route after the user has
 * successfully signed
 */
export const handleLogout = (navigate) => {
  signOut(auth)
    .then(() => {
      navigate('/login');
    })
    .catch((error) => {
      console.error('Error signing out: ', error);
    });
};

export default Auth;
