import React, { useEffect, useState } from 'react';
import Loader from '../loader/loader.js';
import {
  addInvoiceTemplate,
  updateInvoiceTemplate,
} from '../../database/set-invoice-template.js';
import { errorAlert } from '../alerts.js';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import {
  storeLogoInvoice,
  storeBackgroundImage,
} from '../../storage/store-files-invoice';
import { Col, InputNumber, Row, Slider } from 'antd';

const { Dragger } = Upload;

const companyLogo =
  '<p><img src="https://ci3.googleusercontent.com/meips/ADKq_NZ-OrN98PknfmkcPN9vPT1OwLsFpPeSEp49CCf2IVLx47frg-CKOGy2KOc06sLufxGn7VKq70ZAFXJQ36Oeef7LaPA96hRr15u-BL0zEgkMsyqerXt0R65kfjzwgpMvCXq__jxdwqNVZ0IVnwKT4VGXQUbGgvfkie90wcj2VtrLVACaHR1gA9BHnGy84QfIGgUtPr3IejEc-LYcYttEZMZrfR58ME-d87FOFKE4TuLOrQ=s0-d-e1-ft#https://firebasestorage.googleapis.com/v0/b/yoda-technologies-kb.appspot.com/o/serving-again-logo.png?alt=media&amp;token=3dd3a271-c210-407f-a952-7d8e26f9ffc0" alt="Serving Again Logo" width="200" data-bit="iit"></p> ';

function CreateInvoiceTemplateModal({
  openModal,
  setOpenModal,
  selectedTemplate,
  setSelectedTemplate,
  type = 'add',
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [templateTitle, setTemplateTitle] = useState('');
  const [backgroundImageTransparency, setBackgroundImageTransparency] =
    useState(50);
  const [companyLogo, setCompanyLogo] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(false);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [preview, setPreview] = useState(false);
  const [imgPreview, setImgPreview] = useState(null);
  const [fields, setFields] = useState({
    companyDetail: {
      'Company Name': '',
      'Street Address': '',
      City: '',
      State: '',
      Country: '',
      'Zip Code': '',
      Phone: '',
      Email: '',
    },
    companyLogo: {
      'Company Logo': '',
    },
    backgroundImage: {
      'Background Image': '',
      opacity: 50,
    },
    billDetail: {
      'Contact Name': '',
      'Client Company Name': '',
      'Billing Street Address': '',
      'Billing City': '',
      'Billing State': '',
      'Billing Country': '',
      'Billing Zip Code': '',
    },
    invoiceDetail: {
      'Invoice Number': '',
      'Invoice Date': '',
      'Due Date': '',
      Terms: '',
    },
    table: {
      columns: [],
    },
    footer: {
      content: '',
    },
    message: {
      content: '',
    },
    payment: {
      'Company Name': '',
      'Street Address': '',
      City: '',
      State: '',
      Country: '',
      'Zip Code': '',
      Bank: '',
      Account: '',
      Routing: '',
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [editFieldId, setEditFieldId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const changePopup = (id) => {
    // console.log(id);
    if (!openPopup) {
      setEditFieldId(id);
    }
    setOpenPopup(!openPopup);
  };

  const onTransparencyChange = (newValue) => {
    setBackgroundImageTransparency(newValue);
    handleUpdateField('backgroundImage', 'opacity', newValue);
  };

  const handlePaymentMethods = (e) => {
    const value = e.target.value;
    setPaymentMethods((prev) => {
      // Check if the value is already in the array
      if (prev.includes(value)) {
        // If it is, remove it (toggle off)
        return prev.filter((item) => item !== value);
      } else {
        // If it's not, add it (toggle on)
        return [...prev, value];
      }
    });
  };

  useEffect(() => {
    if (
      selectedTemplate &&
      typeof selectedTemplate.template === 'object' &&
      selectedTemplate.template !== null
    ) {
      setFields((prevFields) => ({
        ...prevFields,
        ...selectedTemplate.template,
      }));

      setCompanyLogo();
      // setFields(selectedTemplate.template);
    }
    if (selectedTemplate) {
      setTemplateTitle(selectedTemplate.title);
    }
  }, [selectedTemplate]);

  const handleRemoveColumn = (index) => {
    const tempFields = { ...fields };
    tempFields.table.columns.splice(index, 1);
    setFields(tempFields);
  };

  const saveTemplate = () => {
    const formData = {
      title: templateTitle,
      template: fields,
    };
    // console.log(fields?.table.columns);
    addInvoiceTemplate(formData);
    setOpenModal(false);
    setTemplateTitle('');
    setFields({
      companyDetail: {
        'Company Name': '',
        'Street Address': '',
        City: '',
        State: '',
        Country: '',
        'Zip Code': '',
        Phone: '',
        Email: '',
      },
      companyLogo: {
        'Company Logo': '',
      },
      backgroundImage: {
        'Background Image': '',
        opacity: 50,
      },
      billDetail: {
        'Contact Name': '',
        'Client Company Name': '',
        'Billing Street Address': '',
        'Billing City': '',
        'Billing State': '',
        'Billing Country': '',
        'Billing Zip Code': '',
      },
      invoiceDetail: {
        'Invoice Number': '',
        'Invoice Date': '',
        'Due Date': '',
        Terms: '',
      },
      table: {
        columns: [],
      },
      footer: {
        content: '',
      },
      message: {
        content: '',
      },
      payment: {
        'Company Name': '',
        'Street Address': '',
        City: '',
        State: '',
        Country: '',
        'Zip Code': '',
        Bank: '',
        Account: '',
        Routing: '',
      },
    });
  };

  const updateTemplate = () => {
    const formData = {
      title: templateTitle,
      template: fields,
    };
    // console.log(formData);
    setIsLoading(true);
    updateInvoiceTemplate(selectedTemplate.invoiceTempId, formData);
    setPreview(false);
    setIsLoading(false);
    setOpenModal(false);
    setTemplateTitle('');
    // setFields({});
  };

  const handleAddColumn = (e) => {
    e.preventDefault();
    // console.log(fields?.table.columns);
    const tempFields = { ...fields };
    tempFields.table.columns.push('');
    setFields(tempFields);
  };

  const handleTableUpdate = (index, selectedName) => {
    const selectedField = availableFields.find(
      (field) => field.name === selectedName
    );
    if (!selectedField) return;

    const tempFields = { ...fields };
    tempFields.table.columns[index] = {
      name: selectedField.name,
      label: selectedField.label,
    };
    setFields(tempFields);
  };
  useEffect(() => {
    console.log(fields);
  }, [fields]);
  // This function is called on submission of field-rename-form of a specific field.
  // It updates the label of a specific field in the fields state array.
  const handleEditField = (id, newLabel) => {
    const tempFields = { ...fields };
    const key = id.split('+')[0];
    // console.log(id, newLabel);
    tempFields[key].forEach((field) => {
      if (field.id === id) {
        field.label = newLabel;
      }
    });
    setFields(tempFields);
    setOpenPopup(false);
  };

  // This function is called on change for every field.
  // It updates the value of a specific field in the fields state array.
  const handleUpdateField = (section, name, newValue) => {
    const tempFields = { ...fields };
    // console.log(tempFields[section]);
    tempFields[section][name] = newValue;
    setFields(tempFields);
  };

  const sections = [
    {
      name: 'companyDetail',
      label: 'Company Details',
      heading: 'Add Company Details',
    },
    {
      name: 'billDetail',
      label: 'Billing Details',
      heading: 'Add Billing Details',
      note: '(Add if you want to Fix these values across all Invoices)',
    },
    {
      name: 'invoiceDetail',
      label: 'Invoice Details',
      heading: 'Add Invoice Details',
    },
  ];

  const availableFields = [
    { name: '', label: 'Select a Table Header' },
    { name: 'deliveryQty', label: 'Delivery Qty' },
    { name: 'itemName', label: 'Item Name' },
    { name: 'deliveryAddress', label: 'Delivery Address' },
    { name: 'clin', label: 'CLIN' },
    { name: 'price', label: 'Price' },
    { name: 'saiTotal', label: 'Y Total' },
    { name: 'saiTax', label: 'Y Tax' },
    { name: 'saiS&H', label: 'Y S&H' },
    { name: 'saiTotal', label: 'Y Total' },
    { name: 'saiQty', label: 'Y Qty' },
    { name: 'margin', label: 'Margin' },
    { name: 'g&a', label: 'G&A' },
    { name: 'vpo', label: 'VPO' },
  ];

  const props = {
    name: 'file',
    multiple: false,
    accept: '.png,.jpg,.jpeg',
    maxCount: 1,
    listType: 'picture',
    customRequest: ({ file, onSuccess, onError }) => {
      const fileId = `${Date.now()}-${file.name}`;
      const fileMetadata = { contentType: file.type };

      storeLogoInvoice(file, fileId, fileMetadata)
        .then((downloadURL) => {
          onSuccess(downloadURL, file);
          message.success(`${file.name} uploaded successfully`);
          // console.log('Download URL:', downloadURL);
          setImgPreview(downloadURL);
          handleUpdateField('companyLogo', 'Company Logo', downloadURL);

          // You might want to save this URL in the state or perform other actions with it
        })
        .catch((error) => {
          onError(error);
          message.error(`${file.name} upload failed: ${error.message}`);
        });
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const propsBg = {
    name: 'file',
    multiple: false,
    accept: '.png,.jpg,.jpeg',
    maxCount: 1,
    listType: 'picture',
    customRequest: ({ file, onSuccess, onError }) => {
      const fileId = `${Date.now()}-${file.name}`;
      const fileMetadata = { contentType: file.type };

      storeBackgroundImage(file, fileId, fileMetadata)
        .then((downloadURL) => {
          onSuccess(downloadURL, file);
          message.success(`${file.name} uploaded successfully`);
          // console.log('Download URL:', downloadURL);
          setSelectedBackgroundImage(downloadURL);
          handleUpdateField('backgroundImage', 'Background Image', downloadURL);
        })
        .catch((error) => {
          onError(error);
          message.error(`${file.name} upload failed: ${error.message}`);
        });
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (selectedTemplate && type === 'edit') {
      setTemplateTitle(selectedTemplate.title || '');
      setFields((prevFields) => ({
        ...prevFields,
        ...selectedTemplate.template,
      }));
      setCompanyLogo(
        !!selectedTemplate.template?.companyLogo?.['Company Logo']
      );
      setSelectedLogo(selectedTemplate.template?.companyLogo?.['Company Logo']);
      setSelectedBackgroundImage(
        selectedTemplate.template?.backgroundImage?.['Background Image']
      );
      setImgPreview(selectedTemplate.template?.companyLogo?.['Company Logo']);
    } else {
      // resetForm();
      setPreview(false);
    }
  }, [selectedTemplate, type]);

  return (
    <>
      {isLoading && <Loader />}
      {openPopup && (
        <EditPopup
          changePopup={changePopup}
          id={editFieldId}
          handleEditField={handleEditField}
        />
      )}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative flex p-4 w-full max-w-full max-h-[80%]">
          {/* CUSTOMISE SECTION  */}

          {(type === 'add' || preview) && (
            <div className="relative w-full bg-white shadow dark:bg-gray-700 overflow-y-scroll">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {selectedTemplate ? 'Update' : 'Create'} Invoice Template
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => {
                    setOpenModal(false);
                    setPreview(false);
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
                <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5 space-y-3">
                  <div className="sm:col-span-3 flex items-center">
                    <label
                      htmlFor="template-title"
                      className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-white"
                    >
                      Template Title
                    </label>
                  </div>
                  <div className="sm:col-span-9">
                    <input
                      id="template-title"
                      type="text"
                      value={templateTitle}
                      onChange={(e) => setTemplateTitle(e.target.value)}
                      className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent  dark:placeholder:text-white dark:text-white  dark:border dark:border-solid dark:border-white dark:focus:ring-neutral-600"
                    />
                  </div>
                </div>
                <section className="bg-white dark:bg-gray-900">
                  <div className="mx-auto max-w-5xl">
                    {/* <div className="flex flex-col gap-2 mb-3">
                      <div className="flex gap-4 items-center">
                        <input
                          type="checkbox"
                          class="shrink-0 mt-0.5 dark:text-white border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCompanyLogo(true);
                            } else {
                              setCompanyLogo(false);
                            }
                          }}
                        />
                        <h6 className="dark:text-white">Add Logo</h6>
                      </div>
                      {companyLogo && (
                        <>
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Upload Company Logo. Supports only .png and .jpg
                              files.
                            </p>
                          </Dragger>
                        </>
                      )}
                    </div> */}
                    <div className="flex flex-col gap-2 mb-3">
                      <div className="flex gap-4 items-center">
                        <input
                          type="checkbox"
                          className="shrink-0 mt-0.5 dark:text-white border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          checked={companyLogo}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCompanyLogo(true);
                            } else {
                              setCompanyLogo(false);
                              setSelectedLogo(null); // Clear the selected logo when unchecked
                              setImgPreview(null); // Clear the image preview
                            }
                          }}
                        />
                        <h6 className="dark:text-white">Add Logo</h6>
                      </div>

                      {companyLogo && (
                        <>
                          <Dragger
                            {...props}
                            beforeUpload={(file) => {
                              const isValidFileType =
                                file.type === 'image/png' ||
                                file.type === 'image/jpeg';
                              if (!isValidFileType) {
                                message.error(
                                  'You can only upload .png or .jpg files!'
                                );
                                return false;
                              }
                              const reader = new FileReader();
                              reader.onload = () => {
                                setImgPreview(reader.result); // Set image preview
                                setSelectedLogo(reader.result); // Set uploaded logo
                              };
                              reader.readAsDataURL(file);
                              return false; // Prevent upload to server (if needed)
                            }}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Upload Company Logo. Supports only .png and .jpg
                              files.
                            </p>
                          </Dragger>

                          {imgPreview && (
                            <div className="w-full -mt-2 pb-2 flex ml-2 flex-row items-center">
                              <div className="w-[90%]">
                                <img
                                  src={imgPreview}
                                  alt="Preview"
                                  className="w-32 h-12 object-contain  rounded-md "
                                />
                              </div>
                              <button
                                type="button"
                                className="mt-2 px-2 py-2 rounded-[50%] bg-red-500 text-white"
                                onClick={() => {
                                  setSelectedLogo(null);
                                  setImgPreview(null);
                                }}
                              >
                                <svg
                                  className="w-4 h-4"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </section>

                <section className="bg-white dark:bg-gray-900">
                  <div className="mx-auto max-w-5xl">
                    <div className="flex flex-col gap-2 mb-3">
                      <div className="flex gap-4 items-center">
                        <input
                          type="checkbox"
                          class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setBackgroundImage(true);
                            } else {
                              setBackgroundImage(false);
                            }
                          }}
                        />
                        <h6 className="dark:text-white">
                          Add Background Image
                        </h6>
                      </div>
                      {backgroundImage && (
                        <>
                          <Dragger {...propsBg}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Upload Company Logo. Supports only .png and .jpg
                              files.
                            </p>
                          </Dragger>
                          <Row>
                            <Col span={8}>
                              <label className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                                Set Background Transparency
                              </label>
                            </Col>
                            <Col span={12}>
                              <Slider
                                min={0}
                                max={100}
                                step={5}
                                onChange={onTransparencyChange}
                                value={
                                  typeof backgroundImageTransparency ===
                                  'number'
                                    ? backgroundImageTransparency
                                    : 0
                                }
                              />
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                min={0}
                                max={100}
                                step={5}
                                style={{
                                  margin: '0 16px',
                                }}
                                value={backgroundImageTransparency}
                                onChange={onTransparencyChange}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </section>

                <>
                  {sections.map((section, index) => {
                    return (
                      <section
                        key={index}
                        className="bg-white dark:bg-gray-900"
                      >
                        <div className="flex gap-4 items-center mb-2">
                          <h3 className="text-lg">{section.heading}</h3>
                          {section.note && (
                            <p className="text-sm text-gray-500 dark:text-white">
                              {section.note}
                            </p>
                          )}
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {fields?.[`${section.name}`] &&
                            Object.keys(fields?.[`${section.name}`]).map(
                              (key, index) => {
                                return (
                                  <Field
                                    section={section}
                                    key={index}
                                    label={key}
                                    name={key}
                                    changePopup={changePopup}
                                    id={new Date().toString()}
                                    handleUpdateField={handleUpdateField}
                                    fields={fields}
                                  />
                                );
                              }
                            )}
                        </div>
                      </section>
                    );
                  })}
                </>
                <section className="bg-white dark:bg-gray-900">
                  <div className="flex gap-4 items-center mb-2">
                    <button
                      className=" p-0 pb-1 rounded-full text-xl bg-blue-600 text-white h-[26px] w-[26px] flex justify-center items-center"
                      onClick={(e) => {
                        handleAddColumn(e);
                      }}
                    >
                      {' '}
                      +{' '}
                    </button>
                    <h3 className="text-lg">Add table columns</h3>
                  </div>
                  <div className="flex flex-col gap-4">
                    {fields?.table?.columns?.map((column, index) => {
                      return (
                        <div key={index} className="flex justify-between gap-2">
                          <div className="flex flex-col w-full gap-2">
                            <label
                              htmlFor={index}
                              className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                            >
                              Enter Column {index + 1}
                            </label>
                            <div className="flex justify-between">
                              <select
                                id={index}
                                value={column.name}
                                onChange={(e) =>
                                  handleTableUpdate(index, e.target.value)
                                }
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-800 dark:border-gray-500 dark:text-gray-300"
                              >
                                {availableFields.map((field, fieldIndex) => (
                                  <option key={fieldIndex} value={field.name}>
                                    {field.label}
                                  </option>
                                ))}
                              </select>
                              <button
                                onClick={() => handleRemoveColumn(index)}
                                className="ml-2 p-1 text-red-500 hover:text-red-700"
                                aria-label="Delete column"
                              >
                                <svg
                                  className="w-4 h-4"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>

                <section className="bg-white dark:bg-gray-900">
                  <div className="flex gap-4 items-center mb-2">
                    <h3 className="text-lg">Add Message</h3>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div class="space-y-3">
                      <textarea
                        class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        rows="3"
                        value={fields?.message?.content}
                        onChange={(e) => {
                          handleUpdateField(
                            'message',
                            'content',
                            e.target.value
                          );
                        }}
                        placeholder="You can add a Custom Message over here"
                      ></textarea>
                    </div>
                  </div>
                </section>

                <section className="bg-white dark:bg-gray-900">
                  <div className="flex gap-4 items-center mb-2">
                    <h3 className="text-lg">Add Footer</h3>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div class="space-y-3">
                      <textarea
                        class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        rows="3"
                        value={fields?.footer?.content}
                        onChange={(e) => {
                          handleUpdateField(
                            'footer',
                            'content',
                            e.target.value
                          );
                        }}
                        placeholder="You can add a Custom Footer or Info over here"
                      ></textarea>
                    </div>
                  </div>
                </section>

                <section className="bg-white dark:bg-gray-900">
                  <div className="flex gap-4 items-center mb-2">
                    <h3 className="text-lg">Add Payment Methods</h3>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div class="space-y-3">
                      <div class="flex">
                        <input
                          type="checkbox"
                          name="paymentMethods"
                          value="mail"
                          class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          id="hs-default-checkbox"
                          onChange={(e) => handlePaymentMethods(e)}
                        />
                        <label
                          htmlFor="hs-default-checkbox"
                          class="text-sm text-gray-500 ms-3 dark:text-neutral-400"
                        >
                          Payment by Mail
                        </label>
                      </div>
                      {paymentMethods.includes('mail') && (
                        <div>
                          <p className="underline underline-offset-2">
                            Payment by Mail
                          </p>
                          <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5 space-y-3">
                            {/* Company Name */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-mail-company"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                Company Name
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-mail-company"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'Company Name',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>

                            {/* Street Address */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-mail-street"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                Street Address
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-mail-street"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'Street Address',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>

                            {/* City */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-mail-city"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                City
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-mail-city"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'City',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-2000 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>

                            {/* State */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-mail-state"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                State
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-mail-state"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'State',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>

                            {/* Country */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-mail-country"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                Country
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-mail-country"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'Country',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-4000 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>

                            {/* Zip Code */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-mail-zip"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                Zip Code
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-mail-zip"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'Zip Code',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div class="flex">
                        <input
                          type="checkbox"
                          name="paymentMethods"
                          value="ach"
                          class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          id="hs-checked-checkbox"
                          onChange={(e) => handlePaymentMethods(e)}
                        />
                        <label
                          htmlFor="hs-checked-checkbox"
                          class="text-sm text-gray-500 ms-3 dark:text-neutral-400"
                        >
                          Payment by ACH
                        </label>
                      </div>
                      {paymentMethods.includes('ach') && (
                        <div>
                          <p className="underline underline-offset-2">
                            Payment by ACH
                          </p>
                          <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5 space-y-3">
                            {/* Bank Name */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-ach-bank"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                Bank Name
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-ach-bank"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'Bank',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>

                            {/* Account Number */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-ach-account"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                Account Number
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-ach-account"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'Account',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>

                            {/* Routing Number */}
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="hs-pro-ach-routing"
                                className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"
                              >
                                Routing Number
                              </label>
                            </div>
                            <div className="sm:col-span-9">
                              <input
                                id="hs-pro-ach-routing"
                                type="text"
                                value={fields?.payment?.content}
                                onChange={(e) => {
                                  handleUpdateField(
                                    'payment',
                                    'Routing',
                                    e.target.value
                                  );
                                }}
                                className="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
              <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  type="button"
                  onClick={() => {
                    if (type === 'edit') {
                      updateTemplate();
                    } else {
                      saveTemplate();
                    }
                  }}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {type === 'edit' ? 'Update' : 'Add'} Template
                </button>
                <button
                  onClick={() => {
                    setOpenModal(false);
                    setImgPreview(null);
                    setSelectedLogo(null);
                  }}
                  type="button"
                  className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {/* PREVIEW SECTION */}
          {!preview && (
            <>
              <div className="relative w-full bg-white shadow dark:bg-gray-700 overflow-y-scroll mx-auto">
                {!preview && (
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      {selectedTemplate ? 'Update' : 'Create'} Invoice Template
                    </h3>
                    <div>
                      <button
                        className="text-white mr-2 bg-blue-500 hover:bg-gray-300 hover:text-white rounded-lg text-sm py-2 px-4 ms-auto inline-flex justify-center items-center dark:hover:bg-blue-700 dark:hover:text-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        onClick={() => setPreview(!preview)}
                      >
                        <span className="ml-2">Preview</span>
                      </button>

                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          setOpenModal(false);
                          setPreview(false);
                        }}
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                  </div>
                )}
                <div className="p-4 flex flex-col items-center justify-center">
                  <div className="w-full relative border border-gray-200 rounded-lg p-2 ">
                    <div className="grid grid-cols-3 gap-x-4 gap-y-8">
                      <div className="col-span-2 relative">
                        <p className="text-lg font-bold mb-2">
                          {fields?.['companyDetail']?.['Company Name'] || ''}
                        </p>
                        <p className="text-sm">
                          {fields?.['companyDetail']?.['Street Address'] || ''}
                        </p>
                        <p className="text-sm">
                          {[
                            fields?.['companyDetail']?.['City'] || '',
                            fields?.['companyDetail']?.['State'] || '',
                            fields?.['companyDetail']?.['Country'] || '',
                          ]
                            .filter(Boolean)
                            .join(', ')}
                        </p>
                        <p className="text-sm">
                          {fields?.['companyDetail']?.['Zip Code'] || ''}
                        </p>
                        <p className="text-sm">
                          {fields?.['companyDetail']?.['Phone'] || ''}
                        </p>
                        <p className="text-sm">
                          {fields?.['companyDetail']?.['Email'] || ''}
                        </p>
                      </div>
                      <div className="col-span-1 flex justify-center items-center h-full">
                        {fields?.companyLogo?.['Company Logo'] && (
                          <img
                            src={fields?.companyLogo['Company Logo'] || ''}
                            alt="Company Logo"
                            className="h-24"
                          />
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-x-4 gap-y-8 mt-8">
                      <div className="col-span-2 relative">
                        <p className="text-lg font-bold mb-2">Bill To</p>
                        <p className="text-sm">
                          {fields?.['billDetail']?.['Contact Name'] ||
                            '{{primaryContactName}}'}
                        </p>
                        <p className="text-sm">
                          {fields?.['billDetail']?.['Client Company Name'] ||
                            '{{agency}}'}
                        </p>
                        <p className="text-sm">
                          {fields?.['billDetail']?.['Billing Street Address'] ||
                            '{{streetAddress}}'}
                        </p>
                        <p className="text-sm">
                          {[
                            fields?.['billDetail']?.['Billing City'] ||
                              '{{city}}',
                            fields?.['billDetail']?.['Billing State'] ||
                              '{{state}}',
                            fields?.['billDetail']?.['Billing Country'] ||
                              '{{country}}',
                          ]
                            .filter(Boolean)
                            .join(', ')}
                        </p>
                        <p className="text-sm">
                          {fields?.['billDetail']?.['Billing Zip Code'] ||
                            '{{zipCode}}'}
                        </p>
                      </div>
                      <div className="col-span-2">
                        <p className="text-lg font-bold mb-2">
                          Invoice Details
                        </p>
                        <p className="text-sm">
                          Invoice No:{' '}
                          {fields?.['invoiceDetail']?.['Invoice Number'] ||
                            '{{spo}}'}
                        </p>
                        <p className="text-sm">
                          Invoice Date:{' '}
                          {fields?.['invoiceDetail']?.['Invoice Date'] ||
                            '{{deliveryDate}}'}
                        </p>
                        <p className="text-sm">
                          Due Date:{' '}
                          {fields?.['invoiceDetail']?.['Due Date'] ||
                            '{{30 Days from deliveryDate}}'}
                        </p>
                        {fields?.['invoiceDetail']?.['Terms'] && (
                          <p className="text-sm">
                            Terms:{' '}
                            {fields?.['invoiceDetail']?.['Terms'] ||
                              '{{terms}}'}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {fields?.table?.columns.map((column, index) => (
                              <th
                                key={index}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {column.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {[...Array(3)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                              {fields?.table?.columns?.map((_, colIndex) => (
                                <td
                                  key={colIndex}
                                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                ></td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="grid grid-cols-4 gap-x-4 gap-y-8 mt-2">
                      <div className="col-span-2 flex flex-col justify-center">
                        <p className="text-lg font-bold mb-2">
                          {fields?.message?.content}
                        </p>
                      </div>
                      <div className="col-span-2">
                        {fields?.table?.columns &&
                          fields?.table?.columns?.length > 0 &&
                          fields?.table?.columns[0] !== '' && (
                            <>
                              <table className="min-w-full divide-y divide-gray-200">
                                <tbody className="bg-white divide-y divide-gray-200">
                                  <tr>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 bg-gray-50">
                                      Subtotal:
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                                      {'{{subtotal}}'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 bg-gray-50">
                                      Tax:
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                                      {'{{tax}}'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 bg-gray-50">
                                      Total Due:
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                                      {'{{totalDue}}'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          )}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-x-4 gap-y-8 mt-8">
                      {paymentMethods.includes('mail') && (
                        <div className="col-span-2 relative">
                          <p className="text-lg font-bold mb-2 underline underline-offset-2">
                            Payment by Mail
                          </p>
                          <p className="text-sm">
                            {fields?.['payment']?.['Company Name']}
                          </p>
                          <p className="text-sm">
                            {fields?.['payment']?.['Street Address']}
                          </p>
                          <p className="text-sm">
                            {[
                              fields?.['payment']?.['City'],
                              fields?.['payment']?.['State'],
                              fields?.['payment']?.['Country'],
                            ]
                              .filter(Boolean)
                              .join(', ')}
                          </p>
                          <p className="text-sm">
                            {fields?.['payment']?.['Zip Code']}
                          </p>
                        </div>
                      )}
                      {paymentMethods.includes('ach') && (
                        <div className="col-span-2">
                          <p className="text-lg font-bold mb-2 underline underline-offset-2">
                            Payment by ACH
                          </p>
                          <p className="text-sm">
                            Bank Name: {fields?.['payment']?.['Bank']}
                          </p>
                          <p className="text-sm">
                            Account Number: {fields?.['payment']?.['Account']}
                          </p>
                          <p className="text-sm">
                            Routing Number: {fields?.['payment']?.['Routing']}
                          </p>
                        </div>
                      )}
                    </div>
                    {fields?.footer?.content && (
                      <div className="w-full mt-8 border border-b-0 border-x-0 py-4 border-gray-200">
                        <p className="text-sm text-gray-400">
                          {fields?.footer?.content}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

const Field = ({
  section,
  label,
  name,
  changePopup,
  id,
  handleUpdateField,
  fields,
}) => {
  // const [fieldValue, setFieldValue] = useState('');
  const fieldValue = fields?.[section.name]?.[name] || ''; // Fetch value from parent state

  return (
    <div>
      <div className="flex w-full justify-between">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
        >
          {label}
        </label>
        {/* <button className='flex justify-center items-center rounded-full bg-white border border-black w-[28px] h-[28px]' onClick={() => changePopup(id)}> <EditIcon fontSize='small' /> </button> */}
      </div>
      <input
        type="text"
        id={name}
        name={name}
        value={fieldValue}
        onChange={(e) => {
          // setFieldValue(e.target.value);
          // console.log(section);
          handleUpdateField(section['name'], name, e.target.value);
        }}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-800 dark:border-gray-500 dark:text-gray-300"
      />
    </div>
  );
};

const EditPopup = ({ changePopup, id, handleEditField }) => {
  const [newLabel, setNewLabel] = useState('');

  return (
    <div className="absolute top-1 left-1/2 -translate-x-1/2 w-[30vw] z-[80] bg-white drop-shadow p-4 rounded">
      <div className="flex w-full justify-between">
        <h3 className="text-xl">Edit Field</h3>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={() => {
            changePopup();
          }}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      </div>
      <form
        className="flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault();
          handleEditField(id, newLabel);
        }}
      >
        <div>
          <label
            htmlFor="label"
            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
          >
            Enter field label
          </label>
          <input
            type="text"
            placeholder="e.g: company name"
            id="label"
            name="label"
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-800 dark:border-gray-500 dark:text-gray-300"
          />
        </div>
        <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          {' '}
          Proceed{' '}
        </button>
      </form>
    </div>
  );
};

export default CreateInvoiceTemplateModal;
