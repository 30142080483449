import { useCallback, useEffect, useState } from 'react';
import { updatepos, updateposArray } from '../../database/set-po-data.js';
import { fetchSinglePosDataLive } from '../../database/fetch-pos-data.js';
import { fetchAllVendorsData } from '../../database/fetch-vendor-data.js';
import { fetchInboundEmails } from '../../database/fetch-pos-emails.js';
import Loader from '../loader/loader.js';
import { v4 as uuidv4 } from 'uuid';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { errorAlert, successAlert } from '../alerts.js';
import { storeFilePos } from '../../storage/store-files-pos.js';
import { ReactMultiEmail } from 'react-multi-email';
import { Editor } from '@tinymce/tinymce-react';
import 'react-multi-email/dist/style.css';
import { fetchAllEmailTemplates } from '../../database/fetch-email-template.js';
import PreviewFileModal from '../preview-file-modal.js';
import { fetchAllRfqData } from '../../database/fetch-rfq-data.js';
import updateTablePref from '../../database/set-table-pref.js';
import fetchTablePref from '../../database/fetch-table-pref.js';

function ViewPosComponent({ openEditModal, setOpenEditModal, docId }) {
  const [spo, setSpo] = useState([]);
  const [agencyName, setAgencyName] = useState('');
  const [primaryContactName, setPrimaryContactName] = useState('');
  const [secondaryContactName, setSecondaryContactName] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [viewBids, setViewBids] = useState([]);
  const [modifiedViewBids, setModifiedViewBids] = useState([]);
  const [vendorNames, setVendorNames] = useState([]);
  const [vendorAddQty, setVendorAddQty] = useState(0);
  const [vendorAddPrice, setVendorAddPrice] = useState(0);
  const [vendorAddSAndH, setVendorAddSAndH] = useState(0);
  const [vendorAddTotal, setVendorAddTotal] = useState(0);
  const [vendorAddTax, setVendorAddTax] = useState(0);
  const [rfqEmail, setRfqEmail] = useState('');
  const [attachmentRows, setAttachmentRows] = useState([]);
  const [isAddNewBidModalOpen, setIsAddNewBidModelOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [newBidDetails, setNewBidDetails] = useState({
    uniqueId: '',
    itemDetails: {},
    clin: 0,
    itemName: '',
    vpo: '',
    deliveryAddress: '',
    qty: 0,
    vQty: 0,
    price: 0,
    sAndH: 0,
    tax: 0,
    total: 0,
    saiQty: 0,
    margin: 0,
    gAndA: 0,
    saiSAndH: 0,
    saiTax: 0,
    saiTotal: 0,
    vendorName: '',
    status: 'consider',
  });
  const [emails, setEmails] = useState([]);

  const [attachments, setAttachments] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [description, setDescription] = useState('');
  const [coEmail, setCoEmail] = useState([]);
  const [coFinalEmailContent, setCoFinalEmailContent] = useState([]);
  const [coMailSubject, setCoMailSubject] = useState([]);
  const [vendorOriginalFinalEmailContent, setVendorOriginalFinalEmailContent] =
    useState([]);
  const [vendorOriginalMailSubject, setVendorOriginalMailSubject] = useState(
    []
  );
  const [emailsRQ, setEmailsRQ] = useState([]);
  const [
    vendorConfirmationFinalEmailContent,
    setVendorConfirmationFinalEmailContent,
  ] = useState([]);
  const [vendorConfirmationMailSubject, setVendorConfirmationMailSubject] =
    useState([]);
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [posEmails, setPosEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [headerStatement, setHeaderStatement] = useState('');
  const [currentTab, setCurrentTab] = useState('PO Details');
  const [isContactDetailsVisible, setContactDetailsVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [vendorsData, setVendorsData] = useState([]);
  const [originalQuotesData, setOriginalQuotesData] = useState([]);
  const [modifiedQuotesData, setModifiedQuotesData] = useState([]);
  const [coSelectedTemplate, setCoSelectedTemplate] = useState();
  const [oqSelectedTemplate, setOqSelectedTemplate] = useState();
  const [mqSelectedTemplate, setMqSelectedTemplate] = useState();
  const [tempMailContent, setTempMailContent] = useState('');
  const [tempMailContentVender, setTempMailContentVender] = useState('');

  const [
    isSendCOConfirmationEmailModalOpen,
    setIsSendCOConfirmationEmailModalOpen,
  ] = useState(false);
  const [submittedNewBid, setSubmittedNewBid] = useState(false);
  const [
    isSendUpdateEmailToVendorsModalOpen,
    setIsSendUpdateEmailToVendorsModalOpen,
  ] = useState(false);
  const [attachmentRows1, setAttachmentRows1] = useState([]);
  const [attachmentRows2, setAttachmentRows2] = useState([]);
  const [tempRQMailContent, setTempRQMailContent] = useState('');
  const [finalRQMailContent, setfinalRQMailContent] = useState([]);
  const [attachmentRows3, setAttachmentRows3] = useState([]);
  const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState('');
  const [rfqs, setRfqs] = useState([]);
  const [linkedRfqNo, setLinkedRfqNo] = useState('');
  const [posData, setPosData] = useState({});
  const [tablePreferences, setTablePreferences] = useState({});

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth() is zero-indexed
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}-${year}`;
  };
  useEffect(() => {
    let unsubscribe;

    if (docId) {
      unsubscribe = fetchSinglePosDataLive(docId, (rfqData) => {
        if (rfqData) {
          console.log('posData', rfqData);
          setPosData(rfqData);
          const formattedAwardedDate = formatDate(rfqData.awardedDate);
          const rfqNo = rfqData.rfqNo || 'N/A';
          setHeaderStatement(
            `SPO#${rfqData.spo} - ${rfqData.agency} Awarded On: ${formattedAwardedDate} (RFQ#${rfqNo})`
          );
          setSpo(rfqData.spo || '');
          setAgencyName(rfqData.agency || '');
          setPrimaryContactName(rfqData.primaryContactName || '');
          setSecondaryContactName(rfqData.secondaryContactName || '');
          setPrimaryPhone(rfqData.primaryPhone || '');
          setSecondaryPhone(rfqData.secondaryPhone || '');
          setPrimaryEmail(rfqData.primaryEmail || '');
          setSecondaryEmail(rfqData.secondaryEmail || '');
          setViewBids(rfqData.originalQuotes || []);
          setModifiedViewBids(rfqData.modifiedQuotes || []);
          setPosEmails('');
          setRfqEmail(rfqData.poContent || rfqData.poEmail || '');
          const attachments = rfqData?.attachments || [];
          const packetsattachments = rfqData?.packetsAttachments || [];
          const posattachments = rfqData?.posAttachments || [];
          setAttachmentRows(
            [...attachments, ...packetsattachments, ...posattachments] || []
          );
          setAttachments([
            ...attachments,
            ...packetsattachments,
            ...posattachments,
          ]);
          setDeliveryAddress(rfqData.deliveryAddress || '');
          setDescription(rfqData.description || '');
          setItems(rfqData.items || []);
          setLinkedRfqNo(rfqData.rfqNo ?? '');
          setEmails([rfqData.primaryEmail, rfqData.secondaryEmail]);
        }
      });
    }

    fetchAllEmailTemplates((templates) => {
      setEmailTemplates(templates);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [docId]);

  // useEffect(() => {
  //   let unsubscribe;

  //   if (docId) {
  //     unsubscribe = fetchSinglePosDataLive(docId, (rfqData) => {
  //       if (rfqData) {
  //         console.log('posData', rfqData);
  //         setPosData(rfqData);
  //         const formattedAwardedDate = formatDate(rfqData.awardedDate); // Format the date
  //         setHeaderStatement(
  //           `SPO#${rfqData.spo} - ${rfqData.agency} Awarded On: ${formattedAwardedDate} (RFQ#${rfqData.rfqNo})`
  //         );
  //         setSpo(rfqData.spo || '');
  //         setAgencyName(rfqData.agency || '');
  //         setPrimaryContactName(rfqData.primaryContactName || '');
  //         setSecondaryContactName(rfqData.secondaryContactName || '');
  //         setPrimaryPhone(rfqData.primaryPhone || '');
  //         setSecondaryPhone(rfqData.secondaryPhone || '');
  //         setPrimaryEmail(rfqData.primaryEmail || '');
  //         setSecondaryEmail(rfqData.secondaryEmail || '');
  //         setViewBids(rfqData.originalQuotes || []);
  //         setModifiedViewBids(rfqData.modifiedQuotes || []);
  //         setPosEmails('');
  //         setRfqEmail(rfqData.poContent || rfqData.poEmail || '');
  //         const attachments = rfqData?.attachments || [];
  //         const packetsattachments = rfqData?.packetsAttachments || [];
  //         const posattachments = rfqData?.posAttachments || [];
  //         setAttachmentRows(
  //           [...attachments, ...packetsattachments, ...posattachments] || []
  //         );
  //         setAttachments([
  //           ...attachments,
  //           ...packetsattachments,
  //           ...posattachments,
  //         ]);
  //         setDeliveryAddress(rfqData.deliveryAddress || '');
  //         setDescription(rfqData.description || '');
  //         setItems(rfqData.items || []);
  //         setLinkedRfqNo(rfqData.rfqNo);
  //       }
  //     });
  //   }
  //   fetchAllEmailTemplates((templates) => {
  //     setEmailTemplates(templates);
  //   });

  //   return () => {
  //     if (unsubscribe) {
  //       unsubscribe();
  //     }
  //   };
  // }, [docId]);

  useEffect(() => {
    const unsubscribe = fetchAllVendorsData((vendors) => {
      // console.log('Vendors' + JSON.stringify(vendors, null, 2));
      setVendorsData(vendors);
    });

    fetchTablePref('PoOriginalQuotes')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            PoOriginalQuotes: tableData,
          }));
      })
      .catch((err) => console.log(err));

    fetchTablePref('PoModifiedQuotes')
      .then((tableData) => {
        if (tableData)
          setTablePreferences((prevData) => ({
            ...prevData,
            PoModifiedQuotes: tableData,
          }));
      })
      .catch((err) => console.log(err));

    // Clean up function
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = fetchAllRfqData((rfqs) => {
      console.log('RFQ Data', rfqs);
      const filteredRfqs = rfqs.filter((rfq) => rfq.status !== 'no bid');
      setRfqs(filteredRfqs);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchEmailData = async () => {
      try {
        const newUnsubscribe = await fetchInboundEmails(docId, (emailData) => {
          console.log('Email dataaaaa:', emailData);
          setPosEmails(emailData);
        });
        setUnsubscribe(() => newUnsubscribe);
      } catch (error) {
        console.error('Error fetching email data:', error);
      }
    };

    if (docId) {
      fetchEmailData();
    }

    // Cleanup function to unsubscribe or perform any necessary cleanup
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [docId]);

  useEffect(() => {
    // const docId = '55503aaf-5f4b-4e36-abdf-38a4722452b5';

    const fetchEmailData = async () => {
      try {
        const newUnsubscribe = await fetchInboundEmails(docId, (emailData) => {
          console.log('Email dataaaaa:', emailData);
          setPosEmails(emailData);
        });
        setUnsubscribe(() => newUnsubscribe);
      } catch (error) {
        console.error('Error fetching email data:', error);
      }
    };

    if (docId) {
      fetchEmailData();
    }

    // Cleanup function to unsubscribe or perform any necessary cleanup
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [docId]); // Include docId as a dependency

  // Log posEmails after it has been updated
  useEffect(() => {
    console.log('Updated posEmails:', posEmails);
  }, [posEmails]);

  const handleAttachmentNameChange = (event, index) => {
    const newAttachments = [...attachmentRows];
    newAttachments[index].fileName = event.target.value;
    setAttachmentRows(newAttachments);
  };

  const handleAttachmentFileChange = async (event, index) => {
    setIsLoading(true);
    const newAttachments = [...attachmentRows];
    const file = event.target.files[0];
    newAttachments[index].file = file;
    newAttachments[index].fileName = file.name; // setting the default name as the file's name
    setAttachmentRows(newAttachments);

    if (file) {
      // assuming fileId and fileMetadata are available somewhere in your scope
      const fileId = uuidv4();
      const fileMetadata = {
        contentType: file.type,
      };

      try {
        newAttachments[index].attachmentUrl = await storeFilePos(
          file,
          fileId,
          fileMetadata
        );
        setAttachmentRows(newAttachments);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to upload the file:', error);
        setIsLoading(false);
      }
    }
  };

  const handleAttachmentFileSubmission = async () => {
    const cleanedAttachmentRows = attachmentRows.map((row) => {
      // Use object destructuring to separate the file property
      const { file, ...restOfProperties } = row;
      // Return the rest of properties which excludes file
      return restOfProperties;
    });

    console.log(cleanedAttachmentRows);

    setIsLoading(true);
    try {
      await updateposArray('posAttachments', cleanedAttachmentRows, docId);
    } catch (error) {
      console.error('updateposArray failed:', error);
    }
    setIsLoading(false);
  };

  const handleAttachmentDownloadClick = (event) => {
    const docId = event.currentTarget.getAttribute('data-url');
    window.open(docId, '_blank');
  };

  const handleAttachmentPreviewClick = (event) => {
    const docId = event.currentTarget.getAttribute('data-url');
    setPreviewFileUrl(docId);
    setOpenFilePreviewModal(true);
  };

  const selectedItemsArray = Object.values(selectedItems);

  useEffect(() => {
    const total =
      (parseInt(vendorAddQty) || 0) *
        (parseInt(vendorAddPrice) || 0) *
        (1 + (parseInt(vendorAddTax) || 0) / 100) +
      (parseInt(vendorAddSAndH) || 0);
    setVendorAddTotal(total.toFixed(2));
  }, [vendorAddQty, vendorAddPrice, vendorAddSAndH, vendorAddTax]);

  useEffect(() => {
    const names = vendorsData.map((vendor) => vendor.vendorName);

    // Remove duplicates (if any)
    const uniqueNames = Array.from(new Set(names));

    // Set the unique vendor names in the state
    setVendorNames(uniqueNames);
  }, [vendorsData]);

  const gridStyle = { minHeight: 400 };

  const addAttachmentRow = () => {
    const id = attachmentRows.length
      ? attachmentRows[attachmentRows.length - 1].id + 1
      : 1;
    setAttachmentRows([...attachmentRows, { id }]);
  };

  const saiModifiedQuotesColumns = [
    {
      name: 'clin',
      header: 'CLIN',
      minWidth: 150,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.clin || 150,
      render: ({ value, data }) => <span>{value}</span>,
    },
    {
      name: 'name',
      header: 'Item Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.name || 250,
      render: ({ value, data }) => <span>{value}</span>,
    },
    {
      name: 'vpo',
      header: 'VPO',
      minWidth: 300,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.vpo || 300,
      render: ({ value, data }) => <span>{value}</span>,
    },
    {
      name: 'deliveryAddress',
      header: 'Delivery Address',
      minWidth: 250,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.deliveryAddress || 250,
      render: ({ value, data }) => <span>{value}</span>,
    },
    {
      name: 'qty',
      header: 'Required Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.qty || 100,
      render: ({ value, data }) => <span>{value}</span>,
    },
    {
      name: 'vQty',
      header: 'Vendor Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.vQty || 100,
      render: ({ value, data }) => <span>{value}</span>,
    },
    {
      name: 'price',
      header: 'Price',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.price || 100,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return <span>{`$${formattedValue}`}</span>;
      },
    },
    {
      name: 'sAndH',
      header: 'S&H',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.sAndH || 100,
      render: ({ value, data }) => <span>{`$${value}`}</span>,
    },
    {
      name: 'tax',
      header: 'Tax',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.tax || 100,
      render: ({ value, data }) => <span>{`${value}%`}</span>,
    },
    {
      name: 'total',
      header: 'Total',
      minWidth: 150,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.total || 150,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return <span>{`$${formattedValue}`}</span>;
      },
    },
    {
      name: 'saiQty',
      header: 'Y Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.saiQty || 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={data.status ? 'text-green-500' : 'text-red-500'}
        >{`${value}`}</span>
      ),
    },
    {
      name: 'margin',
      header: 'Margin',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.margin || 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={data.status ? 'text-green-500' : 'text-red-500'}
        >{`${value}%`}</span>
      ),
    },
    {
      name: 'gAndA',
      header: 'G&A',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.gAndA || 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={data.status ? 'text-green-500' : 'text-red-500'}
        >{`$${value}`}</span>
      ),
    },
    {
      name: 'saiSAndH',
      header: 'Y S&H',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.saiSAndH || 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={data.status ? 'text-green-500' : 'text-red-500 '}
        >{`$${value}`}</span>
      ),
    },
    {
      name: 'saiTax',
      header: 'Y Tax',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.saiTax || 100,
      editable: true,
      render: ({ value, data }) => (
        <span
          className={data.status ? 'text-green-500' : 'text-red-500 '}
        >{`${value}%`}</span>
      ),
    },
    {
      name: 'saiTotal',
      header: 'Y Total',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.saiTotal || 100,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={data.status ? 'text-green-500' : 'text-red-500 '}
          >{`$${formattedValue}`}</span>
        );
      },
    },
    {
      name: 'vendorName',
      header: 'Vendor Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.vendorName || 250,
      render: ({ value, data }) => (
        <div>
          <select value={value} onChange={(e) => handleVendorChange(e, data)}>
            {vendorNames.sort().map((vendor) => (
              <option key={vendor} value={vendor}>
                {vendor}
              </option>
            ))}
          </select>
        </div>
      ),
    },
    {
      name: 'status',
      header: '',
      minWidth: 150,
      defaultWidth: tablePreferences?.PoModifiedQuotes?.status || 150,
      render: ({ value, data }) => {
        const handleClick = () => {
          // Toggle the status
          const newStatus = !data.status;
          var indexNo = -1;
          modifiedViewBids.forEach((item, index) => {
            if (item.uniqueId === data.uniqueId) {
              indexNo = index;
            }
          });
          modifiedViewBids[indexNo]['status'] = newStatus;
          updateposArray('modifiedQuotes', modifiedViewBids, docId);
        };

        return (
          <button
            onClick={handleClick}
            className={`px-2 py-1 rounded w-full text-white ${
              value ? 'bg-green-500' : 'bg-red-500'
            }`}
          >
            {/* {value ? 'Confirm' : 'Not Confirm'} */}
            {value ? 'Accept' : 'Withdraw'}
          </button>
        );
      },
    },
    {
      name: 'delete',
      header: '',
      render: ({ value, data }) => {
        const handleDelete = () => {
          // Remove the entry from modifiedViewBids
          const updatedModifiedViewBids = modifiedViewBids.filter(
            (item) => item.uniqueId !== data.uniqueId
          );
          updateposArray('modifiedQuotes', updatedModifiedViewBids, docId);
        };
        return (
          <button
            className="text-red-500 hover:text-red-700 text-center"
            onClick={handleDelete}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mx-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
            {value}
          </button>
        );
      },
    },
  ];

  const getOrderIndexModifiedQuote = (name) => {
    const order = tablePreferences?.PoModifiedQuotes?.order;
    return order ? order.indexOf(name) : -1;
  };

  const orderedModifiedsaiQuotesColumns = saiModifiedQuotesColumns
    .slice()
    .sort(
      (a, b) =>
        getOrderIndexModifiedQuote(a.name) - getOrderIndexModifiedQuote(b.name)
    );

  const handleVendorChange = (e, rowData) => {
    // Implement the logic to update the vendor name in your data
    // For example, you might want to update the state or dispatch an action
    const newVendorName = e.target.value;
    console.log('vendorsData', vendorsData);
    const vendorDetails = vendorsData.find(
      (vendor) => vendor.vendorName === newVendorName
    );
    console.log(vendorDetails);
    const newPrimaryContactName = vendorDetails
      ? vendorDetails.primaryContactName
      : '';
    const newPrimaryEmail = vendorDetails ? vendorDetails.primaryEmail : '';
    console.log(
      'New Vendor Name:',
      newVendorName,
      'Primary Contact Name:',
      newPrimaryContactName,
      'Primary Email:',
      newPrimaryEmail
    );
    const updatedViewBids = viewBids.map((bid) => {
      // Replace 'yourUniqueId' with the specific unique ID you are searching for
      if (bid.uniqueId === rowData.uniqueId) {
        // Replace 'newVendorName' with the new vendor name you want to set
        return {
          ...bid,
          vendorName: newVendorName,
          primaryContactName: newPrimaryContactName,
          primaryEmail: newPrimaryEmail,
        };
      }
      return bid;
    });

    setViewBids(updatedViewBids);
    updateposArray('modifiedQuotes', updatedViewBids, docId);

    console.log(rowData.uniqueId);
    console.log(
      `Updating vendor name for CLIN ${rowData.clin} to ${newVendorName}`
    );
  };

  const originalsaiQuotesColumns = [
    {
      name: 'clin',
      header: 'CLIN',
      minWidth: 150,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.clin || 150,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'name',
      header: 'Item Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.name || 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'vpo',
      header: 'VPO',
      minWidth: 300,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.vpo || 300,
    },
    {
      name: 'deliveryAddress',
      header: 'Delivery Address',
      minWidth: 250,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.deliveryAddress || 250,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'qty',
      header: 'Required Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.qty || 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'vQty',
      header: 'Vendor Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.vQty || 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{value}</span>
      ),
    },
    {
      name: 'price',
      header: 'Price',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.price || 100,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={data.status ? '' : 'line-through'}
          >{`$${formattedValue}`}</span>
        );
      },
    },
    {
      name: 'sAndH',
      header: 'S&H',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.sAndH || 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{`$${value}`}</span>
      ),
    },
    {
      name: 'tax',
      header: 'Tax',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.tax || 100,
      render: ({ value, data }) => (
        <span className={data.status ? '' : 'line-through'}>{`${value}%`}</span>
      ),
    },
    {
      name: 'total',
      header: 'Total',
      minWidth: 150,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.total || 150,
      render: ({ value, data }) => {
        const formattedValue = value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (
          <span
            className={data.status ? '' : 'line-through'}
          >{`$${formattedValue}`}</span>
        );
      },
    },
    {
      name: 'saiQty',
      header: 'Y Qty',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.saiQty || 100,
    },
    {
      name: 'margin',
      header: 'Margin',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.margin || 100,
    },
    {
      name: 'gAndA',
      header: 'G&A',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.gAndA || 100,
    },
    {
      name: 'saiSAndH',
      header: 'Y S&H',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.saiSAndH || 100,
    },
    {
      name: 'saiTax',
      header: 'Y Tax',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.saiTax || 100,
    },
    {
      name: 'saiTotal',
      header: 'Y Total',
      minWidth: 100,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.saiTotal || 100,
    },
    {
      name: 'vendorName',
      header: 'Vendor Name',
      minWidth: 250,
      defaultWidth: tablePreferences?.PoOriginalQuotes?.vendorName || 250,
      render: ({ value, data }) => (
        <a
          href={`mailto:${data.vendorEmail}`}
          className={data.status ? 'underline text-blue-500' : 'line-through'}
        >
          {value}
        </a>
      ),
    },
  ];

  const getOrderIndex = (name) => {
    const order = tablePreferences?.PoOriginalQuotes?.order;
    return order ? order.indexOf(name) : -1;
  };

  const orderedOriginalsaiQuotesColumns = originalsaiQuotesColumns
    .slice()
    .sort((a, b) => getOrderIndex(a.name) - getOrderIndex(b.name));

  const saiQuotesFilterValue = [
    { name: 'clin', operator: 'contains', type: 'string', value: '' },
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    {
      name: 'deliveryAddress',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    { name: 'qty', operator: 'gte', type: 'number', value: '' },
    { name: 'vQty', operator: 'gte', type: 'number', value: '' },
    { name: 'price', operator: 'gte', type: 'number', value: '' },
    { name: 'sAndH', operator: 'gte', type: 'number', value: '' },
    { name: 'tax', operator: 'gte', type: 'number', value: '' },
    { name: 'total', operator: 'gte', type: 'number', value: '' },
    { name: 'saiQty', operator: 'gte', type: 'number', value: '' },
    { name: 'margin', operator: 'gte', type: 'number', value: '' },
    { name: 'gAndA', operator: 'gte', type: 'number', value: '' },
    { name: 'saiSAndH', operator: 'gte', type: 'number', value: '' },
    { name: 'saiTax', operator: 'gte', type: 'number', value: '' },
    { name: 'saiTotal', operator: 'gte', type: 'number', value: '' },
    { name: 'vendorName', operator: 'contains', type: 'string', value: '' },
    { name: 'vpo', operator: 'contains', type: 'string', value: '' },
  ];

  const viewSaiQuotesGridData = useCallback(() => {
    const sortedViewBids = [...viewBids].sort((a, b) => b.status - a.status);
    const formattedBids = sortedViewBids.map((viewBid) => ({
      uniqueId: viewBid.uniqueId,
      clin: viewBid.clin,
      name: viewBid.itemName,
      qty: viewBid.qty,
      vpo: viewBid.vpo,
      description: viewBid.description,
      deliveryAddress: viewBid.deliveryAddress,
      vendorName: viewBid.vendorName,
      vendorEmail: viewBid.primaryEmail,
      status: viewBid.status,
      vQty: parseInt(viewBid.vQty || 0).toFixed(2),
      price: parseInt(viewBid.price || 0).toFixed(2),
      sAndH: parseInt(viewBid.sAndH || 0).toFixed(2),
      tax: parseInt(viewBid.tax || 0).toFixed(2),
      total: (
        parseInt(viewBid.vQty || 0) *
          parseInt(viewBid.price || 0) *
          (1 + parseInt(viewBid.tax || 0) / 100) +
        parseInt(viewBid.sAndH || 0)
      ).toFixed(2),
      saiQty: parseInt(viewBid.saiQty || 0).toFixed(2),
      margin: parseInt(viewBid.margin || 0).toFixed(2),
      gAndA: parseInt(viewBid.gAndA || 0).toFixed(2),
      saiSAndH: parseInt(viewBid.saiSAndH || 0).toFixed(2),
      saiTax: parseInt(viewBid.saiTax || 0).toFixed(2),
      saiTotal:
        viewBid.saiQty === 0
          ? 0
          : (
              parseFloat(viewBid.saiQty || 0) *
                parseFloat(viewBid.price || 0) *
                (1 + parseFloat(viewBid.margin || 0) / 100) *
                (1 + parseFloat(viewBid.saiTax || 0) / 100) +
              parseFloat(viewBid.saiSAndH || 0) +
              parseFloat(viewBid.gAndA || 0) +
              parseFloat(viewBid.sAndH || 0) +
              parseFloat(viewBid.saiQty || 0) *
                parseFloat(viewBid.price || 0) *
                parseFloat(viewBid.tax / 100)
            ).toFixed(2),
    }));
    setOriginalQuotesData(formattedBids);
  }, [viewBids]);

  useEffect(() => {
    viewSaiQuotesGridData();
  }, [viewBids, viewSaiQuotesGridData]);

  const viewModifiedSaiQuotesGridData = useCallback(() => {
    const sortedViewBids = [...modifiedViewBids].sort(
      (a, b) => b.status - a.status
    );
    const formattedBids = sortedViewBids.map((viewBid) => ({
      uniqueId: viewBid.uniqueId,
      clin: viewBid.clin,
      name: viewBid.itemName,
      qty: viewBid.qty,
      vpo: viewBid.vpo,
      description: viewBid.description,
      deliveryAddress: viewBid.deliveryAddress,
      vendorName: viewBid.vendorName,
      vendorEmail: viewBid.primaryEmail,
      status: viewBid.status,
      vQty: parseInt(viewBid.vQty || 0).toFixed(2),
      price: parseInt(viewBid.price || 0).toFixed(2),
      sAndH: parseInt(viewBid.sAndH || 0).toFixed(2),
      tax: parseInt(viewBid.tax || 0).toFixed(2),
      total: (
        parseInt(viewBid.vQty || 0) *
          parseInt(viewBid.price || 0) *
          (1 + parseInt(viewBid.tax || 0) / 100) +
        parseInt(viewBid.sAndH || 0)
      ).toFixed(2),
      saiQty: parseInt(viewBid.saiQty || 0).toFixed(2),
      margin: parseInt(viewBid.margin || 0).toFixed(2),
      gAndA: parseInt(viewBid.gAndA || 0).toFixed(2),
      saiSAndH: parseInt(viewBid.saiSAndH || 0).toFixed(2),
      saiTax: parseInt(viewBid.saiTax || 0).toFixed(2),
      saiTotal:
        viewBid.saiQty === 0
          ? 0
          : (
              parseFloat(viewBid.saiQty || 0) *
                parseFloat(viewBid.price || 0) *
                (1 + parseFloat(viewBid.margin || 0) / 100) *
                (1 + parseFloat(viewBid.saiTax || 0) / 100) +
              parseFloat(viewBid.saiSAndH || 0) +
              parseFloat(viewBid.gAndA || 0) +
              parseFloat(viewBid.sAndH || 0) +
              parseFloat(viewBid.saiQty || 0) *
                parseFloat(viewBid.price || 0) *
                parseFloat(viewBid.tax / 100)
            ).toFixed(2),
    }));

    console.log('modifiedQuotesData', formattedBids);
    setModifiedQuotesData(formattedBids);
  }, [modifiedViewBids]);

  useEffect(() => {
    viewModifiedSaiQuotesGridData();
  }, [viewBids, viewModifiedSaiQuotesGridData]);

  const onEditCompleteViewSaiQuotesGrid = (newData) => {
    var indexNo = -1;
    modifiedViewBids.forEach((item, index) => {
      if (item.uniqueId === newData.rowId) {
        indexNo = index;
      }
    });
    const columnId = newData.columnId;
    modifiedViewBids[indexNo][columnId] = newData.value;

    const saiQty = modifiedViewBids[indexNo]['saiQty'] || 0;
    const price = modifiedViewBids[indexNo]['price'] || 0;
    const prodsaiQtyAndprice = Number(saiQty) * Number(price) || 0;
    const margin = modifiedViewBids[indexNo]['margin'] || 0;
    const prodMargin = (Number(prodsaiQtyAndprice) * Number(margin)) / 100 || 0;
    const ga = Number(modifiedViewBids[indexNo]['gAndA']) || 0;
    const saiSAndH = Number(modifiedViewBids[indexNo]['saiSAndH']) || 0;
    const saiTax = Number(modifiedViewBids[indexNo]['saiTax']) || 0;

    const taxAmount = prodsaiQtyAndprice * (saiTax / 100) || 0;

    const saiTotal =
      prodsaiQtyAndprice + prodMargin + ga + saiSAndH + taxAmount || 0;

    modifiedViewBids[indexNo]['saiTotal'] = saiTotal;

    updateposArray('modifiedQuotes', modifiedViewBids, docId);
  };

  const sendCOConfirmationEmail = async () => {
    console.log(coMailSubject);
    console.log(coEmail);
    console.log(coFinalEmailContent);
    console.log(attachmentRows1);
    setIsLoading(true); // Start loading

    const formData = {
      mailBody: coFinalEmailContent,
      mailSubject: coMailSubject,
      mailAddresses: coEmail,
      mailAttachements: attachmentRows1,
    };

    try {
      const response = await fetch(
        //`${process.env.REACT_APP_URL}send-email/rfq-packet`,
        `${process.env.REACT_APP_URL}/send-email/po-confirmation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        setIsLoading(false);
        throw new Error('Network response was not ok');
      }

      await response.json();
      successAlert('Emails sent successfully');
      setIsSendCOConfirmationEmailModalOpen(false);
      setIsLoading(false);
      // autoAddBid(docId, selectedVendorsArray, selectedItemsArray);
      // updateRFQ(docId, { packetStatus: 'Sent' });
    } catch (error) {
      setIsLoading(false);
      errorAlert('Failed to send request quotes via email!');
    }
  };

  const [isSendEmailToVendorsModalOpen, setIsSendEmailToVendorsModalOpen] =
    useState(false);

  const addNewBid = async () => {
    //  TODO: Create a Type Ahead for Item Name, Delivery Address and Vendor Selection
    const vendorDetails = vendorsData.find(
      (vendor) => vendor.vendorName === newBidDetails.vendorName
    );
    console.log(vendorDetails);
    newBidDetails.primaryContactName = vendorDetails
      ? vendorDetails.primaryContactName
      : '';
    newBidDetails.primaryEmail = vendorDetails
      ? vendorDetails.primaryEmail
      : '';
    setNewBidDetails((prevDetails) => ({ ...prevDetails, uniqueId: uuidv4() }));
    console.log('newwwbiddd' + JSON.stringify(newBidDetails, null, 2));
    setModifiedViewBids((prevBids) => [...prevBids, newBidDetails]);
    setSubmittedNewBid(true);
    console.log('newwwww: ' + modifiedViewBids);

    setIsAddNewBidModelOpen(false);
  };

  useEffect(() => {
    if (submittedNewBid) {
      console.log('aaaaaa' + modifiedViewBids);
      try {
        updateposArray('modifiedQuotes', modifiedViewBids, docId);
      } catch (error) {
        console.log('error occured while updating the pos array' + error);
      }
      setSubmittedNewBid(false);
    }
  }, [modifiedViewBids, submittedNewBid, docId]);

  const finalizeQuotes = async () => {
    setIsLoading(true);
    try {
      await updateposArray('originalQuotes', modifiedViewBids, docId);
      setIsLoading(false);
      successAlert(
        'Quote Finalised',
        'Quotes Finalised Successfully and Updated'
      );
      setOpenEditModal(false);
    } catch (error) {
      errorAlert(
        'Quote Finalisation Failed',
        'Quotes Finalisation was not Completed'
      );
    }
  };

  const sendUpdateEmailToVendors = async () => {
    console.log('originalQuotesData=>', originalQuotesData);
    // const groupedEmails = originalQuotesData.reduce((groups, email) => {
    //   const vendorName = email.vendorName;
    //   if (email.status === false) {
    //     return groups;
    //   }
    //   if (!groups[vendorName]) {
    //     groups[vendorName] = [];
    //   }
    //   groups[vendorName].push(email);
    //   return groups;
    // }, {});

    // console.log(groupedEmails);

    const processedVendors = originalQuotesData.map(
      (quote, index, emailsArray) => {
        const locals = {
          ...quote,
          spo,
          agencyName,
          primaryContactName,
          primaryEmail,
          primaryPhone,
          secondaryContactName,
          secondaryEmail,
          secondaryPhone,
          linkedRfqNo,
        };
        const emailAddress = quote.vendorEmail;
        const emailContent = replaceVariables2(
          vendorConfirmationFinalEmailContent,
          locals
        );
        const emailSubject = replaceSubjectVariables(
          vendorConfirmationMailSubject,
          locals
        );
        return {
          vendorName: quote.vendorName,
          mailBody: emailContent,
          mailAddress: emailAddress,
          mailAttachments: attachmentRows3,
          mailSubject: emailSubject,
        };
      }
    );
    console.log(processedVendors);
    setIsLoading(true);

    try {
      processedVendors.forEach(async (vendor) => {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/send-email/send-vendor-update-email`,
          // 'http://localhost:3001/send-email/send-vendor-update-email',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(vendor),
          }
        );
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Network response was not ok');
        }
        await response.json();
      });
      successAlert('Emails sent successfully');
      setIsSendEmailToVendorsModalOpen(false);
      setSelectedItems([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorAlert('Failed to send request quotes via email!');
    }
  };

  const handleRfqChange = (e) => {
    const newSelectedItemId = e.target.value;
    setSelectedItem(newSelectedItemId);
    const filterRfq = items.find((item) => item.itemId === newSelectedItemId);
    setNewBidDetails((prevDetails) => ({
      ...prevDetails,
      itemDetails: filterRfq,
      itemName: filterRfq.itemName,
      clin: filterRfq.clin,
    }));
  };

  useEffect(() => {
    setCoMailSubject(
      `We have received your Purchase Order request with SPO# ${spo}`
    );

    setVendorConfirmationMailSubject(
      `Purchase Order Request - Confirmation of Quotation and Delivery Details`
    );
    setVendorOriginalMailSubject(
      `Purchase Order Request - Confirmation of Quotation and Delivery Details`
    );
    setCoEmail([primaryEmail, secondaryEmail].filter(Boolean));
  }, [spo, primaryEmail, secondaryEmail]);

  const handleAttachmentChange = (e) => {
    const selectedFile = attachments.find(
      (file) => file.fileName === e.target.value || file.name === e.target.value
    );
    if (selectedFile) {
      setAttachmentRows([
        ...attachmentRows,
        {
          name: selectedFile.fileName || selectedFile.name,
          attachmentUrl: selectedFile.attachmentUrl,
        },
      ]);
    }
  };
  const handleDeleteAttachmentRow = (index) => {
    const newAttachmentRows = [...attachmentRows];
    newAttachmentRows.splice(index, 1);
    setAttachmentRows(newAttachmentRows);
  };

  const handleAttachmentChange1 = (e) => {
    const selectedFile = attachments.find(
      (file) => file.fileName === e.target.value || file.name === e.target.value
    );
    if (selectedFile) {
      setAttachmentRows1([
        ...attachmentRows1,
        {
          name: selectedFile.fileName || selectedFile.name,
          attachmentUrl: selectedFile.attachmentUrl,
        },
      ]);
    }
  };
  const handleDeleteAttachmentRow1 = (index) => {
    const newAttachmentRows = [...attachmentRows1];
    newAttachmentRows.splice(index, 1);
    setAttachmentRows1(newAttachmentRows);
  };

  const handleAttachmentChange2 = (e) => {
    const selectedFile = attachments.find(
      (file) => file.fileName === e.target.value || file.name === e.target.value
    );
    if (selectedFile) {
      setAttachmentRows2([
        ...attachmentRows2,
        {
          name: selectedFile.fileName || selectedFile.name,
          attachmentUrl: selectedFile.attachmentUrl,
        },
      ]);
    }
  };

  const handleDeleteAttachmentRow2 = (index) => {
    const newAttachmentRows = [...attachmentRows2];
    newAttachmentRows.splice(index, 1);
    setAttachmentRows2(newAttachmentRows);
  };
  const handleAttachmentChange3 = (e) => {
    const selectedFile = attachments.find(
      (file) => file.fileName === e.target.value || file.name === e.target.value
    );
    if (selectedFile) {
      setAttachmentRows3([
        ...attachmentRows3,
        {
          name: selectedFile.fileName || selectedFile.name,
          attachmentUrl: selectedFile.attachmentUrl,
        },
      ]);
    }
  };

  const handleDeleteAttachmentRow3 = (index) => {
    const newAttachmentRows = [...attachmentRows3];
    newAttachmentRows.splice(index, 1);
    setAttachmentRows3(newAttachmentRows);
  };

  const selectedVendorsArray = Object.values(selectedItems);

  useEffect(() => {
    const total =
      (parseInt(newBidDetails.vQty) || 0) *
        (parseInt(newBidDetails.price) || 0) *
        (1 + (parseInt(newBidDetails.tax) || 0) / 100) +
      (parseInt(newBidDetails.sAndH) || 0);
    setNewBidDetails((prevDetails) => ({
      ...prevDetails,
      total: total.toFixed(2),
    }));
  }, [
    newBidDetails.vQty,
    newBidDetails.price,
    newBidDetails.tax,
    newBidDetails.sAndH,
  ]);

  useEffect(() => {
    const saiQty = newBidDetails.saiQty || 0;
    const price = newBidDetails.price || 0;
    const prodsaiQtyAndprice = Number(saiQty) * Number(price) || 0;
    const margin = newBidDetails.margin || 0;
    const prodMargin = (Number(prodsaiQtyAndprice) * Number(margin)) / 100 || 0;
    const ga = Number(newBidDetails.gAndA) || 0;
    const saiSAndH = Number(newBidDetails.saiSAndH) || 0;
    const saiTax = Number(newBidDetails.saiTax) || 0;

    const taxAmount = prodsaiQtyAndprice * (saiTax / 100) || 0;

    // console.log('prodsaiQtyAndprice', prodsaiQtyAndprice);
    // console.log('prodMargin', prodMargin);
    // console.log('ga', ga);
    // console.log('saiSAndH', saiSAndH);
    // console.log('taxAmount', taxAmount);

    // const saiTotal =
    //   prodsaiQtyAndprice + prodMargin + ga + saiSAndH + taxAmount || 0;
    const saiTotal = (
      parseFloat(newBidDetails.saiQty || 0) *
        parseFloat(newBidDetails.price || 0) *
        (1 + parseInt(newBidDetails.margin || 0) / 100) *
        (1 + parseInt(newBidDetails.saiTax || 0) / 100) +
      parseFloat(newBidDetails.saiSAndH || 0) +
      parseFloat(newBidDetails.gAndA || 0) +
      parseFloat(newBidDetails.sAndH || 0) +
      parseFloat(newBidDetails.saiQty || 0) *
        parseFloat(newBidDetails.price || 0) *
        parseFloat(newBidDetails.tax / 100)
    ).toFixed(2);

    console.log('saiTotal', saiTotal);

    setNewBidDetails((prevDetails) => ({
      ...prevDetails,
      saiTotal: saiTotal,
    }));
  }, [
    newBidDetails.gAndA,
    newBidDetails.saiQty,
    newBidDetails.saiSAndH,
    newBidDetails.saiTax,
    newBidDetails.price,
    newBidDetails.margin,
    newBidDetails.sAndH,
    newBidDetails.tax,
  ]);

  // const sendEmailToVendors = async () => {
  //   console.log(originalQuotesData);

  //   const processedVendors = originalQuotesData.map((quote) => {
  //     console.log("quote=>",quote);
  //     const locals = {
  //       ...quote,
  //       spo,
  //       agencyName,
  //       primaryContactName,
  //       primaryEmail,
  //       primaryPhone,
  //       secondaryContactName,
  //       secondaryEmail,
  //       secondaryPhone,
  //       linkedRfqNo,
  //     };
  //     const emailAddress = quote.vendorEmail;
  //     const emailContent = replaceVariables2(vendorOriginalFinalEmailContent, locals);
  //     const emailSubject = replaceSubjectVariables(vendorOriginalMailSubject, locals);
  //     return {
  //       vendorName: quote.vendorName,
  //       mailBody: emailContent,
  //       mailAddress: emailAddress,
  //       mailAttachments: attachmentRows2,
  //       mailSubject: emailSubject,
  //     };
  //   });

  //   console.log(processedVendors);
  //   setIsLoading(true);

  //   try {
  //     const responses = await Promise.all(
  //       processedVendors.map(async (vendor) => {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_URL}/send-email/send-vendor-email`,
  //           {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify(vendor),
  //           }
  //         );

  //         if (!response.ok) {
  //           throw new Error(`Network response was not ok for ${vendor.vendorName}`);
  //         }

  //         return response.json();
  //       })
  //     );

  //     console.log('Responses:', responses);
  //     successAlert('Emails sent successfully');
  //   } catch (error) {
  //     console.error('Error sending emails:', error);
  //     errorAlert('Failed to send request quotes via email!');
  //   } finally {
  //     setIsLoading(false);
  //     setIsSendEmailToVendorsModalOpen(false);
  //   }
  // };

  function isValidEmail(email) {
    // Use a regular expression to check for a valid email pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const sendEmailToVendors = async () => {
    console.log(originalQuotesData);
    const validMailAddresses = emailsRQ.filter(isValidEmail);

    const processedVendors = originalQuotesData.map((quote, index) => {
      const locals = {
        ...quote,
        spo,
        agencyName,
        primaryContactName,
        primaryEmail,
        primaryPhone,
        secondaryContactName,
        secondaryEmail,
        secondaryPhone,
        linkedRfqNo,
      };
      const emailAddress = quote.vendorEmail;
      const emailContent = replaceVariables2(
        vendorOriginalFinalEmailContent,
        locals
      );
      const emailSubject = replaceSubjectVariables(
        vendorOriginalMailSubject,
        locals
      );
      return {
        vendorName: quote.vendorName,
        mailBody: emailContent,
        mailAddress: validMailAddresses,
        mailAttachments: attachmentRows2,
        mailSubject: emailSubject,
      };
    });

    console.log(processedVendors, 'processedVendors');
    setIsLoading(true);

    try {
      // Use Promise.all to ensure that we await all API calls before continuing
      const emailRequests = processedVendors.map((vendor) =>
        fetch(`${process.env.REACT_APP_URL}/send-email/send-vendor-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(vendor),
        })
      );

      // Wait for all fetch requests to complete
      const responses = await Promise.all(emailRequests);

      // Check if all responses are successful
      const allResponsesOk = responses.every((response) => response.ok);

      if (allResponsesOk) {
        successAlert('Emails sent successfully');
        setIsSendEmailToVendorsModalOpen(false);
      } else {
        throw new Error('Some emails failed to send');
      }
    } catch (error) {
      console.error(error);
      errorAlert('Failed to send request quotes via email!');
    } finally {
      // Ensure loading state is reset after all API calls are completed
      setIsLoading(false);
    }
  };

  const replaceVariables2 = (template, values) => {
    const variableRegex = /\{\{([^{}]+)\}\}/g;
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/i;
    const hasTable = tableRegex.test(template);

    if (hasTable) {
      const tableContent = template.match(tableRegex)[0];
      const tableRows = tableContent.match(/<tr[^>]*>[\s\S]*?<\/tr>/gi);

      if (tableRows && tableRows.length >= 2) {
        const secondRow = tableRows[1];
        const replacedSecondRow = secondRow.replace(
          variableRegex,
          (match, variable) => {
            return values[variable] || '';
          }
        );

        const modifiedTable = tableContent.replace(
          secondRow,
          replacedSecondRow
        );
        template = template.replace(tableRegex, modifiedTable);
      }
    }

    let replacedTemplate = template.replace(
      variableRegex,
      (match, variable) => {
        return values[variable] || '';
      }
    );

    return replacedTemplate;
  };

  const replaceVariables = (template, values) => {
    const variableRegex = /\{\{([^{}]+)\}\}/g;
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/i;
    // const hasTable = tableRegex.test(template);

    // if (hasTable) {
    //   const tableContent = template.match(tableRegex)[0];
    //   const tableRows = tableContent.match(/<tr[^>]*>[\s\S]*?<\/tr>/gi);

    //   if (tableRows && tableRows.length >= 2) {
    //     const secondRow = tableRows[1];
    //   if (tableRows && tableRows.length >= 2) {
    //     const secondRow = tableRows[1];

    //     const replacedSecondRow = values['items']
    //       .map((item) => {
    //         // Replace variables in the second row with item values
    //         return secondRow.replace(variableRegex, (match, variable) => {
    //           return item[variable] || '';
    //         });
    //       })
    //       .join('');

    //     const modifiedTable = tableContent.replace(
    //       secondRow,
    //       replacedSecondRow
    //     );
    //     template = template.replace(tableRegex, modifiedTable);
    //   }
    // }
    //     const modifiedTable = tableContent.replace(
    //       secondRow,
    //       replacedSecondRow
    //     );
    //     template = template.replace(tableRegex, modifiedTable);
    //   }
    // }

    let replacedTemplate = template.replace(
      variableRegex,
      (match, variable) => {
        return values[variable] || '';
      }
    );

    return replacedTemplate;
  };

  const replaceSubjectVariables = (subject, values) => {
    const variableRegex = /\{\{([^{}]+)\}\}/g;

    return subject.replace(variableRegex, (match, variable) => {
      return values[variable] || '';
    });
  };

  const replaceVariablesTemplate = (template, values) => {
    // Regular expression to match variables in double curly braces
    const variableRegex = /\{\{([^{}]+)\}\}/g;

    // Regular expression to match a table with its content
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/i;

    // Check if the template contains a table
    const hasTable = tableRegex.test(template);

    if (hasTable) {
      // Extract the table content
      const tableContent = template.match(tableRegex)[0];

      // Find all table rows in the table content
      const tableRows = tableContent.match(/<tr[^>]*>[\s\S]*?<\/tr>/gi);

      if (tableRows && tableRows.length >= 2) {
        // Get the second table row
        const secondRow = tableRows[1];

        // Iterate through items array and replace variables in the second row
        const replacedSecondRow = values['items']
          .map((item) => {
            // Replace variables in the second row with item values
            // console.log(item);
            return secondRow.replace(variableRegex, (match, variable) => {
              // // console.log(variable);
              // // console.log(item[variable]);
              return item[variable];
            });
          })
          .join('');

        // Replace the original second row in the table with the modified one
        const modifiedTable = tableContent.replace(
          secondRow,
          replacedSecondRow
        );

        // Replace the original table in the template with the modified one
        template = template.replace(tableRegex, modifiedTable);
      }
    }

    // Replace each variable with its corresponding value
    let variableToReplace = '{{docId}}';
    let replacementValue = values['docId'] || '';

    let replacedTemplate = template.replace(
      variableToReplace,
      replacementValue
    );
    return replacedTemplate;
  };

  const handleTemplateSelection = (emailTempId) => {
    const selectedTemplate = emailTemplates.filter(
      (template) => template.emailTempId === emailTempId
    );

    const local = {
      primaryContactName,
      agencyName,
      spo,
    };

    const rawContent = selectedTemplate[0]?.template;
    const rawSubject = selectedTemplate[0]?.mailSubject;

    if (rawContent) {
      const processedContent = replaceVariables(rawContent, local);
      setCoFinalEmailContent(processedContent);
    }
    if (rawSubject) {
      const processedMailSubject = replaceSubjectVariables(rawSubject, local);
      setCoMailSubject(processedMailSubject);
    }
  };

  const handleOQTemplateSelection = (emailTempId) => {
    const selectedTemplate = emailTemplates.filter(
      (template) => template.emailTempId === emailTempId
    );

    const local = {
      items: selectedItemsArray.map((item) => {
        return {
          clin: item.clin,
          name: item.name,
          deliveryAddress: item.deliveryAddress,
          qty: item.qty,
          vendorCost: item.vendorCost ? item.vendorCost : '',
          saiQty: item.vQty,
          sAndH: item.saiSAndH,
          unit: item.vQty,
          tax: item?.tax,
          total: item.total,
        };
      }),
      docId,
    };

    const rawContent = selectedTemplate[0]?.template;
    const rawSubject = selectedTemplate[0]?.mailSubject;

    if (rawContent) {
      const processedContent = replaceVariablesTemplate(rawContent, local);
      // // console.log(processedContent);
      setTempMailContent(processedContent);
    }
    // setVendorOriginalMailSubject(rawSubject);
    // setVendorOriginalFinalEmailContent(rawContent);
    setVendorOriginalMailSubject(rawSubject);
  };

  const handleMQTemplateSelection = (emailTempId) => {
    const selectedTemplate = emailTemplates.filter(
      (template) => template.emailTempId === emailTempId
    );
    const rawContent = selectedTemplate[0]?.template;
    const rawSubject = selectedTemplate[0]?.mailSubject;

    const local = {
      items: selectedItemsArray.map((item) => {
        return {
          clin: item.clin,
          name: item.name,
          deliveryAddress: item.deliveryAddress,
          qty: item.qty,
          vendorCost: item.vendorCost ? item.vendorCost : '',
          margin: item.margin ? item.margin : '',
          gAndA: item.gAndA,
          vQty: item.saiQty ? item.saiQty : '',
          sAndH: item?.saiSAndH ? item.saiSAndH : '',
          tax: item.saiTax ? item.saiTax : '',
          total: item.saiTotal ? item.saiTotal : '',
          unit: item.unit ? item?.unit : '',
        };
      }),
      docId,
    };
    // setVendorConfirmationFinalEmailContent(rawContent);

    if (rawContent) {
      const processedContent = replaceVariablesTemplate(rawContent, local);
      setTempMailContentVender(processedContent);
    }
    setVendorConfirmationMailSubject(rawSubject);
  };

  const handleChangeLinkedRfqNo = (rfqNo) => {
    const rfq = rfqs.find((rfq) => rfq.rfqNo === rfqNo);

    console.log(rfq);

    setIsLoading(true);
    setLinkedRfqNo(rfq.rfqNo);

    const totalLI = rfq.items.length;
    const clinArray = rfq.items.map((item) => item.clin);
    const spoValue = spo;

    const vpoAddedPo = Array.isArray(rfq.quotes)
      ? rfq.quotes
          .map((item, index) => {
            const position = clinArray.indexOf(item.clin);
            if (position === -1) {
              console.error(
                `Error: CLIN not found for item at index ${index}.`
              );
              return null; // Return null for items with missing CLIN
            }
            let dateObject = new Date(); // Create a new Date object for the current date and time
            let formattedDate = `${
              dateObject.getMonth() + 1
            }/${dateObject.getDate()}/${dateObject.getFullYear()}`;
            return {
              ...item,
              vpo: `${spoValue}-${position + 1}/${totalLI}-${item.vQty || 0}/${
                item.qty || 0
              }-${formattedDate}`,
            };
          })
          .filter((item) => item !== null)
      : [];

    updatepos(docId, {
      ...posData,
      ...rfq,
      modifiedQuotes: vpoAddedPo,
      originalQuotes: vpoAddedPo,
      spo: spo,
    })
      .then(() => {
        successAlert('Linked Rfq Updated');
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        errorAlert('Failed to update linked RFQ');
      });
  };

  const handleColumnResize = async (e, tableName) => {
    console.log('tablePref: ', tablePreferences);
    const updatedColumnName = e.column.name;
    const updatedWidth = e.width;

    const preferences = {
      [updatedColumnName]: updatedWidth,
    };

    await updateTablePref(tableName, preferences);
  };

  const handleColumnOrderChange = async (e, tableName) => {
    await updateTablePref(tableName, { order: e });
  };

  const onSelectionItemsChange = useCallback((config) => {
    let dataArray = [];
    let selectedObject = {};
    if (config.selected === true) {
      dataArray = config.data;
      dataArray.forEach((item) => {
        selectedObject[item.uniqueId] = item;
      });
      setSelectedItems(selectedObject);
    } else {
      setSelectedItems(config.selected);
    }
  }, []);

  useEffect(() => {
    setVendorOriginalFinalEmailContent(tempMailContent);
    // Use a local variable instead of the state for the temporary mail content.
    setVendorConfirmationFinalEmailContent(tempMailContentVender);
  }, [tempMailContent, tempMailContentVender]);

  useEffect(() => {
    // Extract emails from selected vendors
    const initialEmails = Object.values(selectedItems)
      .map((vendor) => vendor?.vendorEmail)
      .filter((email) => email);

    setEmailsRQ(initialEmails);
  }, [selectedItems]);

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openEditModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {headerStatement}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenEditModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <div className="border-b border-gray-200 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                  {[
                    'PO Details',
                    'Attachments',
                    'Original Quotes',
                    'Modified Quotes',
                  ].map((tab) => (
                    <li key={tab} className="flex-grow me-2">
                      <button
                        onClick={() => setCurrentTab(tab)}
                        className={`inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group w-full ${
                          currentTab === tab
                            ? 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500'
                            : ''
                        }`}
                      >
                        {tab}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              {currentTab === 'Attachments' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <button
                        onClick={() =>
                          setContactDetailsVisible(!isContactDetailsVisible)
                        }
                        className="font-bold py-2 cursor-pointer"
                      >
                        Contracting Officer Details{' '}
                        {isContactDetailsVisible ? '▲' : '▼'}
                      </button>
                      {isContactDetailsVisible && (
                        <div>
                          <p className="mb-2 text-sm">
                            <strong>Agency Name:</strong>{' '}
                            {agencyName ? agencyName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Name:</strong>{' '}
                            {primaryContactName ? primaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {primaryPhone ? primaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {primaryEmail ? primaryEmail : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Secondary Contact Name:</strong>{' '}
                            {secondaryContactName ? secondaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {secondaryPhone ? secondaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {secondaryEmail ? secondaryEmail : '--'}
                          </p>
                        </div>
                      )}
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Attachments
                    </h5>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              #
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Attachment Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                              Attachment
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                              <button
                                onClick={addAttachmentRow}
                                type="button"
                                className="p-1 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mx-auto"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  className="h-6 w-6 mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                  />
                                </svg>
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {attachmentRows.map((row, index) => (
                            <tr key={row.id}>
                              <td className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2">
                                {index + 1}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Attachment Name"
                                  value={row.name}
                                  onChange={(event) =>
                                    handleAttachmentNameChange(event, index)
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                />
                              </td>
                              <td className="text-center">
                                {row.attachmentUrl ? (
                                  // <button
                                  //   onClick={() => {
                                  //     window.open(row.attachmentUrl, '_blank');
                                  //   }}
                                  //   className="mx-auto py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                  // >
                                  //   Preview
                                  // </button>
                                  <div>
                                    <button
                                      className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                      data-url={row.attachmentUrl}
                                      onClick={handleAttachmentPreviewClick}
                                    >
                                      Preview
                                    </button>
                                    <button
                                      className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                      data-url={row.attachmentUrl}
                                      onClick={handleAttachmentDownloadClick}
                                    >
                                      Download
                                    </button>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    placeholder="Attachment"
                                    onChange={(event) =>
                                      handleAttachmentFileChange(event, index)
                                    }
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2"
                                  />
                                )}
                              </td>
                              <td className="text-center">
                                <button
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Are you sure you want to delete this row?'
                                      )
                                    ) {
                                      handleDeleteAttachmentRow(index);
                                    }
                                  }}
                                  className="text-red-500 hover:text-red-700 text-center"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 mx-auto"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="w-full text-right">
                      <button
                        type="button"
                        onClick={() => {
                          handleAttachmentFileSubmission();
                        }}
                        className="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        Update Attachments
                      </button>
                    </div>
                  </section>
                </>
              )}
              {currentTab === 'PO Details' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Contracting Officer Details
                    </h5>
                    <div>
                      <p className="mb-2">
                        <strong>Agency Name:</strong>{' '}
                        {agencyName ? agencyName : '--'}
                      </p>
                      <p className="mb-2">
                        <strong>Contact Name:</strong>{' '}
                        {primaryContactName ? primaryContactName : '--'}
                      </p>
                      <p className="mb-2">
                        <strong>Contact Number:</strong>{' '}
                        {primaryPhone ? primaryPhone : '--'}
                      </p>
                      <p className="mb-2">
                        <strong>Contact Email:</strong>{' '}
                        {primaryEmail ? primaryEmail : '--'}
                      </p>
                      <p className="mb-2">
                        <strong>Secondary Contact Name:</strong>{' '}
                        {secondaryContactName ? secondaryContactName : '--'}
                      </p>
                      <p className="mb-2">
                        <strong>Contact Number:</strong>{' '}
                        {secondaryPhone ? secondaryPhone : '--'}
                      </p>
                      <p className="mb-2">
                        <strong>Contact Email:</strong>{' '}
                        {secondaryEmail ? secondaryEmail : '--'}
                      </p>
                    </div>
                    <div className="col-span-12">
                      <label
                        htmlFor="linkedRfq"
                        className="col-span-1 mb-2 font-bold text-gray-900 dark:text-white items-center inline-flex"
                      >
                        Linked RFQ:
                      </label>
                      <select
                        id="linkedRfq"
                        value={linkedRfqNo}
                        className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold"
                        placeholder="Write the Email Subject Over Here"
                        onChange={(e) =>
                          handleChangeLinkedRfqNo(e.target.value)
                        }
                      >
                        <option value=""> Select RFQ</option>
                        {rfqs?.map((rfq) => {
                          return (
                            <option key={rfq.rfqNo} value={rfq.rfqNo}>
                              {rfq.rfqNo}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <h5 className="text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      PO Email Received
                    </h5>
                  </section>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="htmlContent"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Content
                    </label>
                    <div
                      contentEditable={false}
                      className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      dangerouslySetInnerHTML={{ __html: rfqEmail }}
                    />
                  </div>
                </>
              )}
              {currentTab === 'Original Quotes' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <button
                        onClick={() =>
                          setContactDetailsVisible(!isContactDetailsVisible)
                        }
                        className="font-bold py-2 cursor-pointer"
                      >
                        Contracting Officer Details{' '}
                        {isContactDetailsVisible ? '▲' : '▼'}
                      </button>
                      {isContactDetailsVisible && (
                        <div>
                          <p className="mb-2 text-sm">
                            <strong>Agency Name:</strong>{' '}
                            {agencyName ? agencyName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Name:</strong>{' '}
                            {primaryContactName ? primaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {primaryPhone ? primaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {primaryEmail ? primaryEmail : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Secondary Contact Name:</strong>{' '}
                            {secondaryContactName ? secondaryContactName : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Number:</strong>{' '}
                            {secondaryPhone ? secondaryPhone : '--'}
                          </p>
                          <p className="mb-2 text-sm">
                            <strong>Contact Email:</strong>{' '}
                            {secondaryEmail ? secondaryEmail : '--'}
                          </p>
                        </div>
                      )}
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Originally Received Quotes{' '}
                    </h5>
                    <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="flex justify-between space-x-2">
                        <ReactDataGrid
                          idProperty="uniqueId"
                          style={gridStyle}
                          enableFiltering
                          defaultFilterValue={saiQuotesFilterValue}
                          columns={orderedOriginalsaiQuotesColumns}
                          onColumnResize={(e) => {
                            handleColumnResize(e, 'PoOriginalQuotes');
                          }}
                          onColumnOrderChange={(e) =>
                            handleColumnOrderChange(e, 'PoOriginalQuotes')
                          }
                          dataSource={originalQuotesData}
                          pagination
                          defaultLimit={20}
                          rowHeight={60}
                          enableSelection
                          enableKeyboardNavigation
                          selected={selectedItems}
                          checkboxColumn
                          // showZebraRows={false}
                          onSelectionChange={onSelectionItemsChange}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
              {currentTab === 'Modified Quotes' && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="w-full inline-flex">
                      <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        <button
                          onClick={() =>
                            setContactDetailsVisible(!isContactDetailsVisible)
                          }
                          className="font-bold py-2 cursor-pointer"
                        >
                          Contracting Officer Details{' '}
                          {isContactDetailsVisible ? '▲' : '▼'}
                        </button>
                        {isContactDetailsVisible && (
                          <div>
                            <p className="mb-2 text-sm">
                              <strong>Agency Name:</strong>{' '}
                              {agencyName ? agencyName : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Name:</strong>{' '}
                              {primaryContactName ? primaryContactName : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Number:</strong>{' '}
                              {primaryPhone ? primaryPhone : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Email:</strong>{' '}
                              {primaryEmail ? primaryEmail : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Secondary Contact Name:</strong>{' '}
                              {secondaryContactName
                                ? secondaryContactName
                                : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Number:</strong>{' '}
                              {secondaryPhone ? secondaryPhone : '--'}
                            </p>
                            <p className="mb-2 text-sm">
                              <strong>Contact Email:</strong>{' '}
                              {secondaryEmail ? secondaryEmail : '--'}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Y Quotes{' '}
                      <span className="text-sm">
                        (Double click on the Coloured Cells to Edit Details)
                      </span>
                    </h5>
                    <div className="block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="flex justify-between space-x-2">
                        <ReactDataGrid
                          idProperty="uniqueId"
                          style={gridStyle}
                          enableFiltering
                          defaultFilterValue={saiQuotesFilterValue}
                          columns={orderedModifiedsaiQuotesColumns}
                          onColumnResize={(e) => {
                            console.log(e);
                            handleColumnResize(e, 'PoModifiedQuotes');
                          }}
                          onColumnOrderChange={(e) =>
                            handleColumnOrderChange(e, 'PoModifiedQuotes')
                          }
                          dataSource={modifiedQuotesData}
                          onEditComplete={onEditCompleteViewSaiQuotesGrid}
                          pagination
                          defaultLimit={20}
                          rowHeight={60}
                          enableSelection
                          editable={true}
                          enableColumnAutosize={true}
                          enableKeyboardNavigation
                          selected={selectedItems}
                          showZebraRows={false}
                          checkboxColumn
                          // showZebraRows={false}
                          onSelectionChange={onSelectionItemsChange}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              {currentTab === 'Original Quotes' && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setIsSendEmailToVendorsModalOpen(true);
                    }}
                    className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 mx-3"
                  >
                    Send Email to Vendors
                  </button>
                </>
              )}
              {currentTab === 'PO Details' && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setIsSendCOConfirmationEmailModalOpen(true);
                    }}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Reply to CO
                  </button>
                </>
              )}
              {currentTab === 'Modified Quotes' && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setIsAddNewBidModelOpen(true);
                    }}
                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  >
                    Add new Bid
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      finalizeQuotes();
                    }}
                    className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Finalize Quotes
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsSendUpdateEmailToVendorsModalOpen(true);
                    }}
                    className="ms-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Send Update Emails to Vendors
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        tabIndex="-1"
        aria-hidden="true"
        className={
          isSendCOConfirmationEmailModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Send Confirmation Email
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setIsSendCOConfirmationEmailModalOpen(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <label
                    htmlFor="shipment_subject"
                    className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                  >
                    Choose Template
                  </label>
                  <select
                    id="shipment_subject"
                    value={coSelectedTemplate}
                    className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the Email Subject Over Here"
                    onChange={(e) => {
                      handleTemplateSelection(e.target.value);
                      setCoSelectedTemplate(e.target.value);
                    }}
                  >
                    <option value="">Select Template</option>
                    {emailTemplates?.map((template) => {
                      if (
                        template.mailType === 'pos_co' ||
                        template.mailType === 'thank_you'
                      ) {
                        return (
                          <option
                            key={template.emailTempId}
                            value={template.emailTempId}
                          >
                            {template.title}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
                <label
                  htmlFor="shipment_to"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  To
                </label>
                <div className="col-span-11 mb-4">
                  <ReactMultiEmail
                    placeholder="Input your email"
                    emails={coEmail}
                    onChange={(_emails) => {
                      setCoEmail(_emails);
                    }}
                    // onFocus={() => setShipmentFocused(true)}
                    // onBlur={() => setShipmentFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div
                          data-tag
                          key={index}
                          data-tag-item
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px',
                            border: '1px solid black',
                            borderRadius: '3px',
                          }}
                        >
                          <span>{email}</span>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6 mb-6">
                <label
                  htmlFor="shipment_subject"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="shipment_subject"
                  value={coMailSubject}
                  className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write the Email Subject Over Here"
                  required
                  onChange={(e) => {
                    setCoMailSubject(e.target.value);
                  }}
                />
              </div>
              <div className="w-full">
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                  init={{
                    plugins:
                      ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject('See docs to implement AI Assistant')
                      ),
                  }}
                  value={coFinalEmailContent}
                  onEditorChange={(content, editor) => {
                    setCoFinalEmailContent(content);
                  }}
                />
              </div>
              <div className="w-full mt-4">
                <section className="bg-white dark:bg-gray-900">
                  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Attachment Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Attachment
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div class="">
                              <label htmlFor="states" className="sr-only">
                                Choose a Existing File
                              </label>
                              <select
                                onChange={handleAttachmentChange1}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                {attachments.map((attachment, index) => {
                                  const label =
                                    attachment.fileName ||
                                    attachment.name ||
                                    `File-${Math.floor(
                                      100 + Math.random() * 900
                                    )}`;

                                  return (
                                    <option key={index} value={label}>
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attachmentRows1.map((row, index) => (
                          <tr key={row.id}>
                            <td>
                              <input
                                type="text"
                                placeholder="Attachment Name"
                                value={row.name}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                              />
                            </td>
                            <td>
                              <a
                                href={row.attachmentUrl}
                                rel="noreferrer"
                                target="_blank"
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2 px-4 py-2"
                              >
                                {row.attachmentUrl}
                              </a>
                            </td>
                            <td className="text-center">
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this row?'
                                    )
                                  ) {
                                    handleDeleteAttachmentRow1(index);
                                  }
                                }}
                                className="text-red-500 hover:text-red-700 text-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => sendCOConfirmationEmail()}
              >
                Send Email
              </button>
              <button
                onClick={() => {
                  setIsSendCOConfirmationEmailModalOpen(false);
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        tabIndex="-1"
        aria-hidden="true"
        className={
          isSendEmailToVendorsModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Send Emails to Vendors
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setIsSendEmailToVendorsModalOpen(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 overflow-y-auto max-h-[calc(100vh-15rem)]">
              {/* {combinedClinData.map((item, index) => {
                if (item.qty !== item.saiQty) {
                  return (
                    <div
                      key={index}
                      className="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>
                        <strong>
                          CLIN {item.clin} ({item.name}){' '}
                        </strong>{' '}
                        has <strong>{item.qty}</strong> required quantities but{' '}
                        <strong>{item.saiQty}</strong> SAI quantities.
                      </div>
                    </div>
                  );
                }
                return null;
              })} */}

              <div className="grid grid-cols-12 gap-6 mb-6">
                <div className="col-span-12">
                  <label
                    htmlFor="shipment_subject"
                    className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                  >
                    Choose Template
                  </label>
                  <select
                    id="shipment_subject"
                    value={oqSelectedTemplate}
                    className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the Email Subject Over Here"
                    onChange={(e) => {
                      handleOQTemplateSelection(e.target.value);
                      setOqSelectedTemplate(e.target.value);
                    }}
                  >
                    <option value="">Select Template</option>
                    {emailTemplates?.map((template) => {
                      if (
                        template.mailType === 'pos_oq' ||
                        template.mailType === 'thank_you'
                      ) {
                        return (
                          <option
                            key={template.emailTempId}
                            value={template.emailTempId}
                          >
                            {template.title}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
                <label
                  htmlFor="shipment_subject"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="shipment_subject"
                  value={vendorOriginalMailSubject}
                  className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write the Email Subject Over Here"
                  required
                  onChange={(e) => {
                    setVendorOriginalMailSubject(e.target.value);
                  }}
                />
              </div>

              <div className="grid grid-cols-12 gap-6 mb-6">
                <label
                  htmlFor="shipment_to"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  To
                </label>
                <div className="col-span-11 mb-4">
                  <ReactMultiEmail
                    placeholder="Input your email"
                    emails={emailsRQ}
                    onChange={(_emails) => {
                      setEmailsRQ(_emails);
                    }}
                    // onFocus={() => setShipmentFocused(true)}
                    // onBlur={() => setShipmentFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div
                          data-tag
                          key={index}
                          data-tag-item
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px',
                            border: '1px solid black',
                            borderRadius: '3px',
                          }}
                        >
                          <span>{email}</span>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="w-full">
                {/* <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                  init={{
                    plugins:
                      ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject('See docs to implement AI Assistant')
                      ),
                  }}
                  value={vendorOriginalFinalEmailContent}
                  onEditorChange={(content, editor) => {
                    setVendorOriginalFinalEmailContent(content);
                  }}
                /> */}
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                  init={{
                    plugins:
                      ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject('See docs to implement AI Assistant')
                      ),
                  }}
                  value={vendorOriginalFinalEmailContent}
                  onEditorChange={(content, editor) => {
                    setVendorOriginalFinalEmailContent(content);
                  }}
                />
              </div>
              <div className="w-full mt-4">
                <section className="bg-white dark:bg-gray-900">
                  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Attachment Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Attachment
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div class="">
                              <label htmlFor="states" className="sr-only">
                                Choose a Existing File
                              </label>
                              <select
                                onChange={handleAttachmentChange2}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-e-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                {attachments.map((attachment, index) => {
                                  const label =
                                    attachment.fileName ||
                                    attachment.name ||
                                    `File-${Math.floor(
                                      100 + Math.random() * 900
                                    )}`;

                                  return (
                                    <option key={index} value={label}>
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attachmentRows2.map((row, index) => (
                          <tr key={row.id}>
                            <td>
                              <input
                                type="text"
                                placeholder="Attachment Name"
                                value={row.name}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                              />
                            </td>
                            <td>
                              <a
                                href={row.attachmentUrl}
                                rel="noreferrer"
                                target="_blank"
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2 px-4 py-2"
                              >
                                {row.attachmentUrl}
                              </a>
                            </td>
                            <td className="text-center">
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this row?'
                                    )
                                  ) {
                                    handleDeleteAttachmentRow2(index);
                                  }
                                }}
                                className="text-red-500 hover:text-red-700 text-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => sendEmailToVendors()}
              >
                Send Email
              </button>
              <button
                onClick={() => {
                  setIsSendEmailToVendorsModalOpen(false);
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        tabIndex="-1"
        aria-hidden="true"
        className={
          isAddNewBidModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add New Bid
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setIsAddNewBidModelOpen(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="selectRFQOptions"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select an Existing Item
                  </label>
                  <select
                    id="selectRFQOptions"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                    value={selectedItem}
                    onChange={handleRfqChange}
                  >
                    <option>Choose a Existing Item</option>
                    {items.map((item) => (
                      <option key={item.itemId} value={item.itemId}>
                        {item.itemName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="clin"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    CLIN <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="clin"
                    id="clin"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="CLIN"
                    required
                    value={newBidDetails.clin}
                    // onChange={(e) =>
                    //   setNewBidDetails((prevDetails) => ({
                    //     ...prevDetails,
                    //     clin: e.target.value,
                    //   }))
                    // }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="itemName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Item Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="itemName"
                    id="itemName"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Item Name"
                    required
                    value={newBidDetails.itemName}
                    // onChange={(e) => setAwardedDateValue(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="VPO"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    VPO <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="VPO"
                    id="VPO"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="VPO"
                    required
                    value={newBidDetails.vpo}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        vpo: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="deliveryAddress"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Delivery Address <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="deliveryAddress"
                    id="deliveryAddress"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Delivery Address"
                    required
                    value={newBidDetails.deliveryAddress}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        deliveryAddress: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="requiredQty"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Required Quantity <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="requiredQty"
                    id="requiredQty"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Required Qty"
                    required
                    value={newBidDetails.qty}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        qty: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="vendorQty"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Vendor Qty <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="vendorQty"
                    id="vendorQty"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Vendor Qty"
                    required
                    value={newBidDetails.vQty}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        vQty: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="price"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Price <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Price"
                    required
                    value={newBidDetails.price}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        price: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="SandH"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    S&H <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="SandH"
                    id="SandH"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="S&H"
                    required
                    value={newBidDetails.sAndH}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        sAndH: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="tax"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tax <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="tax"
                    id="tax"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Tax"
                    required
                    value={newBidDetails.tax}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        tax: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="total"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Total <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="total"
                    id="total"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Total"
                    required
                    value={newBidDetails.total}
                    // onChange={(e) =>
                    //   setNewBidDetails((prevDetails) => ({
                    //     ...prevDetails,
                    //     total: e.target.value,
                    //   }))
                    // }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="SAIQty"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Y Qty <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="SAIQty"
                    id="SAIQty"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Y Qty"
                    required
                    value={newBidDetails.saiQty}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        saiQty: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="margin"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Margin <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="margin"
                    id="margin"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Margin"
                    required
                    value={newBidDetails.margin}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        margin: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="GandA"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    G&A <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="GandA"
                    id="GandA"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="G&A"
                    required
                    value={newBidDetails.gAndA}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        gAndA: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="SAISandH"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Y S&H <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="SAISandH"
                    id="SAISandH"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Y S&H"
                    required
                    value={newBidDetails.saiSAndH}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        saiSAndH: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="SAITax"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Y Tax <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="SAITax"
                    id="SAITax"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Y tax"
                    required
                    value={newBidDetails.saiTax}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        saiTax: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="SAITotal"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Y Total <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    name="SAITotal"
                    id="SAITotal"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Y total"
                    required
                    value={newBidDetails.saiTotal}
                    // onChange={(e) =>
                    //   setNewBidDetails((prevDetails) => ({
                    //     ...prevDetails,
                    //     SAITotal: e.target.value,
                    //   }))
                    // }
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="vendorName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Vendor Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="vendorName"
                    id="vendorName"
                    list="vendorData"
                    className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Vendor Name"
                    required
                    value={newBidDetails.vendorName}
                    onChange={(e) =>
                      setNewBidDetails((prevDetails) => ({
                        ...prevDetails,
                        vendorName: e.target.value,
                      }))
                    }
                  />
                  <datalist id="vendorData">
                    {vendorsData.map((vendor) => {
                      return <option value={vendor.vendorName} />;
                    })}
                  </datalist>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => addNewBid()}
              >
                Add New Bid
              </button>
              <button
                onClick={() => {
                  setIsAddNewBidModelOpen(false);
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        tabIndex="-1"
        aria-hidden="true"
        className={
          isSendUpdateEmailToVendorsModalOpen
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Send Emails to Vendors
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setIsSendUpdateEmailToVendorsModalOpen(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 overflow-y-auto max-h-[calc(100vh-15rem)]">
              {/* {combinedClinData.map((item, index) => {
                if (item.qty !== item.saiQty) {
                  return (
                    <div
                      key={index}
                      className="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>
                        <strong>
                          CLIN {item.clin} ({item.name}){' '}
                        </strong>{' '}
                        has <strong>{item.qty}</strong> required quantities but{' '}
                        <strong>{item.saiQty}</strong> SAI quantities.
                      </div>
                    </div>
                  );
                }
                return null;
              })} */}

              <div className="grid grid-cols-12 gap-6 mb-6">
                <div className="col-span-12">
                  <label
                    htmlFor="shipment_subject"
                    className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                  >
                    Choose Template
                  </label>
                  <select
                    id="shipment_subject"
                    value={mqSelectedTemplate}
                    className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the Email Subject Over Here"
                    onChange={(e) => {
                      handleMQTemplateSelection(e.target.value);
                      setMqSelectedTemplate(e.target.value);
                    }}
                  >
                    <option value="">Select Template</option>
                    {emailTemplates?.map((template) => {
                      if (
                        template.mailType === 'pos_mq' ||
                        template.mailType === 'thank_you'
                      ) {
                        return (
                          <option
                            key={template.emailTempId}
                            value={template.emailTempId}
                          >
                            {template.title}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
                <label
                  htmlFor="shipment_subject"
                  className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="shipment_subject"
                  value={vendorConfirmationMailSubject}
                  className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write the Email Subject Over Here"
                  required
                  onChange={(e) => {
                    setVendorConfirmationMailSubject(e.target.value);
                  }}
                />
              </div>
              <div className="w-full">
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                  init={{
                    plugins:
                      ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject('See docs to implement AI Assistant')
                      ),
                  }}
                  value={vendorConfirmationFinalEmailContent}
                  onEditorChange={(content, editor) => {
                    setVendorConfirmationFinalEmailContent(content);
                  }}
                />
              </div>
              <div className="w-full mt-4">
                <section className="bg-white dark:bg-gray-900">
                  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Attachment Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Attachment
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div class="">
                              <label htmlFor="states" className="sr-only">
                                Choose a Existing File
                              </label>
                              <select
                                onChange={handleAttachmentChange3}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-e-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                {attachments.map((attachment, index) => {
                                  const label =
                                    attachment.fileName ||
                                    attachment.name ||
                                    `File-${Math.floor(
                                      100 + Math.random() * 900
                                    )}`;

                                  return (
                                    <option key={index} value={label}>
                                      {label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attachmentRows3.map((row, index) => (
                          <tr key={row.id}>
                            <td>
                              <input
                                type="text"
                                placeholder="Attachment Name"
                                value={row.name}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                              />
                            </td>
                            <td>
                              <a
                                href={row.attachmentUrl}
                                rel="noreferrer"
                                target="_blank"
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2 px-4 py-2"
                              >
                                {row.attachmentUrl}
                              </a>
                            </td>
                            <td className="text-center">
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this row?'
                                    )
                                  ) {
                                    handleDeleteAttachmentRow3(index);
                                  }
                                }}
                                className="text-red-500 hover:text-red-700 text-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => sendUpdateEmailToVendors()}
              >
                Send Email
              </button>
              <button
                onClick={() => {
                  setIsSendUpdateEmailToVendorsModalOpen(false);
                }}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <PreviewFileModal
        openModal={openFilePreviewModal}
        setOpenModal={setOpenFilePreviewModal}
        fileLink={previewFileUrl}
        attachments={attachmentRows}
      />
    </>
  );
}

export default ViewPosComponent;
