import React, { useCallback, useEffect, useState } from 'react';
import { fetchAllEmailTemplates } from '../../database/fetch-email-template';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import CreateEmailTemplateModal from './create-email-temp-modal';
import { deleteEmailTemplate } from '../../database/set-email-template';

const gridStyle = { height: '65vh' };

const EmailTemplateTable = () => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [openEmailTemplateModal, setOpenEmailTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  useEffect(() => {
    fetchAllEmailTemplates((templates) => {
      setEmailTemplates(templates);
    });
  }, []);

  const handleMailTypeRender = ({ value }) => {
    const mailTypes = {
      rfq: 'RFQ',
      rfq_rq: 'RFQ Request Quotes',
      pos_oq: 'POS Original Quotes',
      pos_mq: 'POS Modified Quotes',
      pos_co: 'POS Confirmation',
      del_sh: 'Deliveries Shipment',
      del_in: 'Deliveries Invoices',
      thank_you: 'Thank you',
    };
    return mailTypes[value] || value;
  };

  const onDeleteRenderer = ({ data }) => (
    <button
      type="button"
      onClick={() => deleteEmailTemplate(data.docId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );

  const tableColumns = () => [
    {
      name: 'index',
      header: '#',
      minWidth: 50,
      render: ({ rowIndex }) => (
        <div style={{ textAlign: 'center' }}>{rowIndex + 1}</div>
      ),
    },
    { name: 'title', header: 'Title', minWidth: 250 },
    {
      name: 'mailType',
      header: 'Mail Type',
      minWidth: 250,
      render: handleMailTypeRender,
    },
    {
      name: 'delete',
      header: 'Delete',
      minWidth: 50,
      render: onDeleteRenderer,
    },
  ];

  const tableFilterValue = [
    { name: 'title', operator: 'contains', type: 'string', value: '' },
    { name: 'mailType', operator: 'contains', type: 'string', value: '' },
  ];

  const handleRowClick = useCallback(
    (props) => {
      const clickedData = props.data;
      const matchingTemplate = emailTemplates.find(
        (template) => template.emailTempId === clickedData.emailTempId
      );

      if (matchingTemplate) {
        setSelectedTemplate(matchingTemplate);
        setOpenEmailTemplateModal(true);
      } else {
        console.warn(
          'No matching template found in state for ID:',
          clickedData.emailTempId
        );
      }
    },
    [emailTemplates]
  );

  return (
    <>
      <ReactDataGrid
        style={gridStyle}
        idProperty="emailTempId"
        defaultFilterValue={tableFilterValue}
        columns={tableColumns()}
        dataSource={emailTemplates}
        pagination
        defaultLimit={10}
        rowHeight={60}
        onRowClick={handleRowClick}
        showCellBorders={'horizontal'}
        columnMenu={{ lockable: false }}
      />

      <CreateEmailTemplateModal
        openModal={openEmailTemplateModal}
        setOpenModal={setOpenEmailTemplateModal}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
    </>
  );
};

export default EmailTemplateTable;
